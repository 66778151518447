import React, { useState } from "react";
import axios from "axios";

const SpeechToText = ({ language, user }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setError("Please select an audio file to upload.");
      return;
    }

    setLoading(true);
    setError("");
    setTranscription("");

    const formData = new FormData();
    formData.append("audio", selectedFile);
    formData.append("language", language);

    try {
      const response = await axios.post(`${SERVER_ROOT}/api/speechtotext`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      setTranscription(response.data.transcription);

    } catch (err) {
      console.error(err);
      setError("Failed to process the audio file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{marginTop: '100px'}}>
      <h1>Speech to Text</h1>
      <p>User: {user.displayName|| "Unknown"}</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="audioFile">Upload an audio file:</label>
          <input
            type="file"
            id="audioFile"
            accept="audio/*"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Processing..." : "Convert to Text"}
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {transcription && (
        <div>
          <h2>Transcription:</h2>
          <p>{transcription}</p>
        </div>
      )}
    </div>
  );
};

export default SpeechToText;
