
// Filename: Clipboard.js

import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill'; // React Quill for WYSIWYG editing
import { Helmet } from 'react-helmet'; // react-helmet import
import 'react-quill/dist/quill.snow.css'; // React Quill styles
import axios from 'axios';
import './Clipboard.css'; 
import { RiFileReduceLine } from "react-icons/ri";
import { RiFileDownloadLine } from "react-icons/ri";
import { TbMailShare } from "react-icons/tb";
// import Quill from 'quill'; //never delete - This module supports Youtube video link

// import WaveformPlayer from './WaveformPlayer'; // WaveformPlayer 컴포넌트 추가
// import DOMPurify from 'dompurify'; // HTML 정제를 위한 라이브러리 설치 필요

const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const isMobileDevice = isMobile();

let modules;
let formats;

if (isMobileDevice) {
    modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic'],
            [{ align: [] }],  
            ['link', 'image', 'video'],
            ['code-block'],
        ],
    };
    formats = ['header', 'bold', 'italic', 'align', 'link', 'image', 'video', 'code-block'];
} else {
    modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic'],
            [{ align: [] }],  
            ['link', 'image', 'video'],
            ['code-block'],
        ],
    };
    formats = ['header', 'bold', 'italic', 'align', 'link', 'image', 'video', 'code-block'];
} 

function Clipboard({ language, user }) {
    const [showSplash, setShowSplash] = useState(true);
    const [activeTab, setActiveTab] = useState('write'); 

    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [tooltipContent, setTooltipContent] = useState('');

    // const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 

    console.log(`SERVER_ROOT: ${SERVER_ROOT}`);

    const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리 

     
    // Handle splash screen
    useEffect(() => {
        const timer = setTimeout(() => setShowSplash(false), 100);
        return () => clearTimeout(timer);
    }, []); 

    const storedUser = JSON.parse(localStorage.getItem('uk_user'));
    if (!user) {
        user = storedUser;
    }

    const uid = user ? user.uid : 'unknown';
    const displayName = user ? user.displayName : 'Guest';
    const email = user ? user.email : '';
 
    console.log(`user.uid: ${user.uid}`);
    console.log(`user.displayName: ${user.displayName}`);
    console.log(`user.email: ${user.email}`); 

    const handleMouseEnter = (url) => {
    // if (!isMobileDevice) {
        setTooltipContent(url);
        setTooltipVisible(true);
    // }
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    }; 

    
 
    return (
        <div className="Diary">
            <Helmet>
            <title>Clipboard - Write Memos and Diary | Doldari</title>
            {/* <link rel="icon" href="%PUBLIC_URL%/images/thumbnail_doldari_clipboard.png"/>  */}
            <meta
                name="description"
                content="Doldari Clipboard is a versatile digital memo pad for saving text, images, and files with seamless organization and preview features."
            />
            <meta
                name="keywords"
                content="clipboard, memo, diary, online, free"
            />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="Doldari Clipboard" />
            <meta
                property="og:description"
                content="Doldari Clipboard is a versatile digital memo pad for saving text, images, and files with seamless organization and preview features."
            />
            <meta property="og:url" content="https://www.doldari.com/clipboard" />
            <meta property="og:type" content="website" />  
            <meta property="og:image" content="https://www.doldari.com/images/thumbnail_doldari_clipboard.png" /> 
            </Helmet>
            {/* <header>
                <h1>{language === 'KR' ? '돌다리 클립보드' : 'Doldari Clipboard'}</h1>
            </header> */}

            <main>
            <div className='myDiaryContainer'
                onClick={(e) => {
                // 클릭한 요소가 mySites의 자식 요소가 아니라면 스크롤 실행
                if (e.target === e.currentTarget) {
                    window.scrollTo(0, 0);
                }
            }}>  
                <div className='myDiaryTitle'>
                    {/* <button className="Greetings">{getValueByName(webpageData, 'App.js_Greetings') || "오늘의 뉴스"}</button> */}
                    <div className="Greetings-clipboard">
                        {language === 'KR' ? '간단한 메모, 일기, 파일을 기록하세요.' : 'Write Memo and diary on Doldari.'}
                    {/* <img 
                        src={language === 'KR' ? '/images/doldari_clipboard_title.png' : '/images/doldari_clipboard_title.png'} 
                        alt={language === 'KR' ? '돌다리 클립보드' : 'Doldari Clipboard'} 
                        style={{ width: 'auto', height: '120px' }} 
                        onMouseEnter={() => handleMouseEnter(
                        language === 'KR' ? '텍스트, 이미지, 파일을 클립보드에 저장하세요.' : 'Save your text, image, and file on this clipboard.' )}
                        onMouseOut={handleMouseLeave}
                    /> */}
                    </div>
                </div> 
                <div className="tab-buttons">
                <button
                    className={activeTab === 'write' ? 'active' : ''}
                    onClick={() => setActiveTab('write')}
                >
                    {language === 'KR' ? '메모 쓰기' : 'New Memo'}
                </button>
                <button
                    className={activeTab === 'view' ? 'active' : ''}
                    onClick={() => setActiveTab('view')}
                >
                    {language === 'KR' ? '메모 보기' : 'View Diary'}
                </button>
                </div>
                <div className='myDiary'> 
                    {activeTab === 'write' && (
                        <DiaryForm 
                            user={{ uid, displayName, email }}
                            language={language} 
                        />
                    )}
                    
                    {activeTab === 'view' && (
                        <DiaryList 
                            user={{ uid, displayName, email }}
                            language={language}
                        />
                    )}
                </div>
            </div>
            </main>
        </div>
    );
}

function DiaryForm({ user, language }) {
    const [diaryText, setDiaryText] = useState('');
    // const [selectedDate, setSelectedDate] = useState('');
    const [voiceFile, setVoiceFile] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [files, setFiles] = useState([]); 
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const quillRef = useRef(null);

    // const [currentDateTime, setCurrentDateTime] = useState('');
    
    
    // const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // const isMobileDevice = isMobile();
 
    const containerHeightDefault = isMobileDevice ? "62%" : "85%";
    const quillHeightDefault = isMobileDevice ? "100%" : "89%";

    const [containerHeight, setContainerHeight] = useState(containerHeightDefault);
    const [quillHeight, setQuillHeight] = useState(quillHeightDefault); 

    // 일기 내용 업데이트
    const handleTextChange = (value) => {
        setDiaryText(value);

        if (!isMobileDevice) {
            adjustHeight(value);
          }
    };

    const adjustHeight = (value) => {
        const lineHeight = 24; // 한 줄의 높이 (Quill에서 텍스트 라인의 평균 높이)
        const padding = 20; // 상하 패딩 합
        const editor = quillRef.current.getEditor();
        const lineCount = editor.getLines().length; // Quill에서 총 라인 수
    
        const buf = lineHeight * lineCount + padding; 

        const newHeight = buf > quillHeightDefault ? buf : quillHeightDefault;

        const newContainerHeight = newHeight;
     
        
        // Quill과 container 높이를 동적으로 변경
        setQuillHeight(`${newHeight}px`);
        setContainerHeight(`${newContainerHeight }px`);
      };

    // 파일 업로드 처리
    const handleFileUpload = (e) => {
        setFiles([...files, ...Array.from(e.target.files)]); // 기존 파일에 새 파일 추가
    };

    // 폼 제출 처리
    const handleSubmit = async () => {
        
        if (!user || !selectedDate || !diaryText) {
            setSubmissionStatus(
                language === 'KR' ? '날짜와 내용을 작성해야 합니다.' : 'Date and Text fields must be filled out.'
            );
            return;
        }

        setIsSubmitting(true); // Change the button text to "Wait, please"


        const formData = new FormData();
        formData.append('userId', user.uid);
        formData.append('name', user.displayName);
        formData.append('email', user.email);
        formData.append('selectedDate', selectedDate); 
        formData.append('diaryText', encodeURIComponent(diaryText));
        

        console.log(`user.uid: ${user.uid}`);
        console.log(`user.displayName: ${user.displayName}`);
        console.log(`user.email: ${user.email}`);
        console.log(`selectedDate: ${selectedDate}`); 
        console.log(`diaryText: ${diaryText}`);

        // if (voiceFile) formData.append('voiceFile', voiceFile);
        // if (photoFile) formData.append('photoFile', photoFile);
        // files.forEach((file) => formData.append('files', file)); // 파일 배열 추가

        if (voiceFile) formData.append('voiceFile', new File([voiceFile], encodeURIComponent(voiceFile.name)));
        if (photoFile) formData.append('photoFile', new File([photoFile], encodeURIComponent(photoFile.name)));
        files.forEach((file) =>
            formData.append('files', new File([file], encodeURIComponent(file.name))) // 한글 파일 이름 처리
        );

        try {
            setSubmissionStatus(language === 'KR' ? '업로드가 진행중입니다. 잠시만 기다리세요.' : 'Upload is in progress...');

            await axios.post(`${SERVER_ROOT}/api/diary`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setSubmissionStatus(language === 'KR' ? '일기가 성공적으로 저장되었습니다.' : 'Diary saved successfully!');
            setDiaryText('');
            setSelectedDate('');
            setVoiceFile(null);
            setPhotoFile(null);
            setIsSubmitting(false); // Reset the button text back to "Submit"

            // onSave();
        } catch (error) {
            console.error('Error saving diary:', error);
            setSubmissionStatus(
                language === 'KR' ? '일기를 저장하는 중 오류가 발생했습니다.' : 'An error occurred while saving the diary.'
            );
        }

        document.body.scrollIntoView({ behavior: 'smooth' });
    };

   
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [selectedDate, setSelectedDate] = useState(getTodayDate()); // 초기값으로 현재 날짜 설정


    // 압축 함수
    const compressImage = (imageFile, maxWidth, maxHeight, quality = 0.8) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    // 이미지 크기 조정
                    if (width > maxWidth || height > maxHeight) {
                        if (width / height > maxWidth / maxHeight) {
                            height = (maxWidth / width) * height;
                            width = maxWidth;
                        } else {
                            width = (maxHeight / height) * width;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        (blob) => {
                            resolve(blob);
                        },
                        'image/jpeg', // 압축된 이미지 형식
                        quality // 품질 (0.8 기본)
                    );
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(imageFile);
        });
    };

    const handlePaste = async (e) => { 

        if (!quillRef.current) return;
        const quill = quillRef.current.getEditor();

        // 현재 커서 위치 저장
        const range = quill.getSelection();
        if (!range || range.index == null) return;


        
        const clipboardItems = e.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                e.preventDefault();
                const imageFile = item.getAsFile();
    
                if (imageFile) {
                    try {
                        console.log('compress image before uploading');
                        const compressedImage = await compressImage(imageFile, 400, 400); // 최대 크기 800x800
                        const reader = new FileReader();
                        reader.onload = () => {
                            // const range = quillRef.current.getEditor().getSelection(); // 현재 커서 위치
                            quill.insertEmbed(range.index, 'image', reader.result);
                            quill.setSelection(range.index + 1); 
                        };
                        reader.readAsDataURL(compressedImage);
                    } catch (error) {
                        console.error('Error compressing image:', error);
                    }
                }
            }
        }
    };

    useEffect(() => {
        const quillEditor = quillRef.current.getEditor();
        quillEditor.root.addEventListener('paste', handlePaste);

        return () => {
            quillEditor.root.removeEventListener('paste', handlePaste);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            // iOS 키보드가 사라졌을 때 동작
            if (window.innerHeight === document.documentElement.clientHeight) {
                // 키보드가 닫힌 후 스크롤 복구
                document.body.scrollIntoView({ behavior: 'smooth' });
            }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    
    const handleBlur = () => {
        document.body.scrollIntoView({ behavior: 'smooth' });
    };
    
    

    return (
        <div className="DiaryForm">
            {/* <h2>{language === 'KR' ? '메모와 일기를 남겨보세요.' : 'Write a Diary'}</h2>  */}
            
            <div className="dateSelection"> 
                <label>{'Date: '}
                    <input 
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}  
                    />
                </label>
            </div>
            <div
                className="textArea"
                style={{
                    height: containerHeight,
                    // overflow: "visible",
                    transition: "height 0.3s ease", // 높이 변경 시 부드러운 애니메이션
                }}
            >
                {/* <label>{language === 'KR' ? '텍스트' : 'Text'}:</label> */}
                <ReactQuill
                    ref={quillRef}
                    value={diaryText}
                    onChange={handleTextChange}
                    onBlur={handleBlur}
                    style={{
                        width: "98%",
                        height: quillHeight,
                        marginLeft: "1%",
                        fontSize: "10px",
                        // overflow: "visible", 
                      }}
                    modules={modules}
                    formats={formats}
                    theme="snow" 
                    placeholder={language === 'KR' ? (
                        "여기에 메모할 제목과 내용을 기록하세요. 형식은 자유입니다. 파일 크기는 10 MB이하, 한번에 최대 10개까지 업로드 가능합니다. " 
                    ) : ( 
                        "Add your text and image clip here... You can paste or insert from your album. Have fun!"
                    )}
                />

                <ul>
                <ul style={{ fontSize: '12px', textAlign: 'left', marginLeft: '20px'}}>
                    {files.map((file, index) => (
                        <li key={index}>{file.name}</li>
                    ))}
                </ul>
                </ul>
            </div>
            {/* <div className="form-group">
                <label>{'Audio: '}</label>
                <input type="file" accept="audio/*" onChange={(e) => handleFileUpload(e, setVoiceFile)} />
                
            </div>
            <div className="form-group">
                <label>{'Image: '}</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileUpload(e, setPhotoFile)} />
                
            </div> */}
            <div className="fileUpload">
                <label>{language === 'KR' ? '파일 업로드:' : 'Files to upload:'}</label>
                <input type="file" multiple onChange={handleFileUpload} />
                
            </div>

            {submissionStatus && <p className="status-message" style={{ backgroundColor: 'yellow' }}>{submissionStatus}</p>}
            {/* <button className='submitButton' onClick={handleSubmit} >{language === 'KR' ? '제출' : 'Submit'}</button> */}
            <button 
            className="submitButton" 
            onClick={handleSubmit}
            disabled={isSubmitting && !submissionStatus} // Optionally disable the button while submitting
            >
            {isSubmitting && !submissionStatus
                ? (language === 'KR' ? '잠시만 기다려주세요' : 'Wait, please') 
                : (language === 'KR' ? '제출' : 'Submit')
            }
            </button>
            
            
        </div>
    );
}

function DiaryList({ user, language }) {
    const [diaries, setDiaries] = useState([]);
    const [selectedDiary, setSelectedDiary] = useState(null);
    const [selectedDiaryId, setSelectedDiaryId] = useState(null);
    const [error, setError] = useState(null);
    const [showViewer, setShowViewer] = useState({});

    const quillRef = useRef(null);

    // const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';
    
    const [isLocalData, setIsLocalData] = useState(false); // 로컬 데이터 여부 

    // Ref for focusing selectedDiary
    const selectedDiaryRef = useRef(null);
    
    const containerHeightDefault = isMobileDevice ? "62%" : "85%";
    const quillHeightDefault = isMobileDevice ? "100%" : "100%";

    const [containerHeight, setContainerHeight] = useState(containerHeightDefault);
    const [quillHeight, setQuillHeight] = useState(quillHeightDefault); 

    const [isEditing, setIsEditing] = useState(false);
    const [editedDiaryText, setEditedDiaryText] = useState('');
    const [editedFiles, setEditedFiles] = useState([]);

    // 일기 내용 업데이트
    const handleTextChange = (value) => { 
        setEditedDiaryText(value);

        if (!isMobileDevice) {
            adjustHeight(value);
          }
    };

    const adjustHeight = (value) => {
        const lineHeight = 24; // 한 줄의 높이 (Quill에서 텍스트 라인의 평균 높이)
        const padding = 20; // 상하 패딩 합
        const editor = quillRef.current.getEditor();
        const lineCount = editor.getLines().length; // Quill에서 총 라인 수
    
        const buf = lineHeight * lineCount + padding; 

        const newHeight = buf > quillHeightDefault ? buf : quillHeightDefault;

        const newContainerHeight = newHeight;
     
        
        // Quill과 container 높이를 동적으로 변경
        setQuillHeight(`${newHeight}px`);
        setContainerHeight(`${newContainerHeight }px`);
      }; 

    useEffect(() => {
        if (selectedDiary && selectedDiaryRef.current) {
            // Focus on the selected diary when it changes
            selectedDiaryRef.current.focus();
        }
    }, [selectedDiary]);

    console.log('In DiaryList ---');
    console.log(`user.uid: ${user.uid}`);
    console.log(`user.displayName: ${user.displayName}`);
    console.log(`user.email: ${user.email}`);  
    
    useEffect(() => {
        // user.email이 존재할 때만 fetchDiaryList 호출
        if (user && user.email) {
            fetchDiaryList(user.email);
        }
    }, [user.email]); // user.email이 변경될 때만 실행
 
    // useEffect(() => {
    //     if (user && user.email) {
    //         fetchDiaryList(user.email);
    //     } else {
    //         loadLocalDiaries(); // 로컬 데이터 로드
    //     }
    // }, [user.email]); // user.email이 변경될 때만 실행

    // Fetch diary list
    const fetchDiaryList = async (email) => {
        try {
            console.log(`Fetching diary list for: ${email}`);
            const response = await axios.get(`${SERVER_ROOT}/api/diary`, { params: { email } });
            setDiaries(response.data);
            setIsLocalData(false);

            // 로컬 스토리지에 데이터 저장
            localStorage.setItem("diaryData", JSON.stringify(response.data));
        } catch (err) {
            console.error("Error fetching diary list:", err);
            setError(language === 'EN' ? 'This is the content from local storage.' : '로컬에 저장된 내용입니다.');

            // 서버에서 데이터를 불러오지 못하면 로컬 데이터 로드
            loadLocalDiaries();
        }
    };

    const fetchDiaryDetails = async (id) => {
        try { 
            const response = await axios.get(`${SERVER_ROOT}/api/diary/${id}`);
            setSelectedDiaryId(id);
            setSelectedDiary(response.data); 
            setIsLocalData(false);

            // 로컬 스토리지에 선택된 일기 저장
            localStorage.setItem(`diary_${id}`, JSON.stringify(response.data));
            console.log(`Saved into local storage: diary_${id}`);

        } catch (err) {
            console.error("Error fetching diary details:", err);
            setError(language === 'EN' ? 'This is the content from local storage.' : '로컬 저장소의 내용입니다.');

            // 로컬 데이터 로드
            loadLocalSelectedDiary(id);
        }
    };

    const loadLocalDiaries = () => {
        const localData = localStorage.getItem("diaryData");
        if (localData) {
            setDiaries(JSON.parse(localData));
            setIsLocalData(true);
        }
    };

    const loadLocalSelectedDiary = (id) => {
        console.log(`--loadLocalSelectedDiary id: ${id}`);
        const localData = localStorage.getItem(`diary_${id}`);
        if (localData) {
            const diary = JSON.parse(localData);
            if (diary.id === id) {
                setSelectedDiary(diary);
                setSelectedDiaryId(id);
                setIsLocalData(true);
            }
        }
    };

    

    const handleDiaryClick = (id) => {
        console.log(`--handleDiaryClick id: ${id}`);
        fetchDiaryDetails(id); 
    };

    const handleCloseDetailClick = (id) => {
        setSelectedDiary(null);
        setSelectedDiaryId(null);

        if (selectedDiaryRef.current) {
            selectedDiaryRef.current.focus();
        }
    };

    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText;
    };
    
    const handleVoiceFileDownload = () => {
        const link = document.createElement('a');
        link.href = `${SERVER_ROOT}/uploads/${selectedDiary.voiceFileName}`;
        link.download = selectedDiary.voiceFileName; // 다운로드될 파일 이름 지정
        link.click();
    };

    const handleDelete = async (id) => {
        if (!window.confirm(language === 'EN' ? 'Are you sure you want to delete this record?' : '정말 삭제하시겠습니까?')) {
            return;
        }
    
        try {
            const response = await axios.delete(`${SERVER_ROOT}/api/diary/${id}`);
            if (response.status === 200) {
                alert(language === 'EN' ? 'Record deleted successfully.' : '기록이 성공적으로 삭제되었습니다.');
                // Remove the deleted record from the state
                setDiaries((prevDiaries) => prevDiaries.filter((diary) => diary.id !== id));
            } else {
                alert(language === 'EN' ? 'Failed to delete the record.' : '기록 삭제에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            alert(language === 'EN' ? 'An error occurred while deleting the record.' : '기록을 삭제하는 중 오류가 발생했습니다.');
        }
    };
    
 
    function isEncoded(uri) {
        try {
            return uri !== decodeURIComponent(uri);
        } catch (e) {
            return false; // 디코딩 불가한 경우 URI가 아님
        }
    }


    const handlePinchZoom = (event) => {
        if (event.touches.length === 2) {
            const content = event.currentTarget;
    
            const touch1 = event.touches[0];
            const touch2 = event.touches[1];
    
            const distance = Math.sqrt(
                Math.pow(touch2.pageX - touch1.pageX, 2) +
                Math.pow(touch2.pageY - touch1.pageY, 2)
            );
    
            if (!content.lastDistance) {
                content.lastDistance = distance;
    
                // 초기 터치 중심 설정
                const rect = content.getBoundingClientRect();
                const centerX = (touch1.pageX + touch2.pageX) / 2 - rect.left;
                const centerY = (touch1.pageY + touch2.pageY) / 2 - rect.top;
    
                content.style.transformOrigin = `${centerX}px ${centerY}px`;
            }
    
            const scale = distance / content.lastDistance;
            const currentScale = content.scale || 1;
            const newScale = Math.min(Math.max(currentScale * scale, 1), 4); // 1~4배 제한
    
            content.style.transform = `translate(${content.translateX || 0}px, ${content.translateY || 0}px) scale(${newScale})`;
            content.scale = newScale;
            content.lastDistance = distance;
        }
    };
    
    const handleTouchEnd = (event) => {
        const content = event.currentTarget;
        delete content.lastDistance;
    };
    
    const handleDragStart = (event) => {
        if (event.touches.length === 1) {
            const content = event.currentTarget;
            content.dragging = true;
    
            const touch = event.touches[0];
            content.startX = touch.pageX - (content.translateX || 0);
            content.startY = touch.pageY - (content.translateY || 0);
        }
    };
    
    const handleDragMove = (event) => {
        if (event.touches.length === 1) {
            const content = event.currentTarget;
            if (content.dragging) {
                const touch = event.touches[0];
                const translateX = touch.pageX - content.startX;
                const translateY = touch.pageY - content.startY;
    
                content.style.transform = `translate(${translateX}px, ${translateY}px) scale(${content.scale || 1})`;
                content.translateX = translateX;
                content.translateY = translateY;
            }
        }
    };
    
    const handleDragEnd = (event) => {
        const content = event.currentTarget;
        content.dragging = false;
    };
    
    const handleFileDownload = async (filename) => {
        try {
          const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';
          const fileUrl = `${SERVER_ROOT}/uploads/${filename}`;
      
          const response = await fetch(fileUrl);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const blob = await response.blob();
      
          // 파일 다운로드를 위한 링크 생성
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = filename; // 다운로드될 파일 이름 설정
          downloadLink.style.display = 'none';
      
          // 링크 클릭을 통해 다운로드 트리거
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error('File download failed:', error);
          alert('Failed to download the file.');
        }
    };
      
    const handleShareDiaryClick = (diaryId) => {
        const recipientEmail = prompt("Enter recipient's email address:");
        if (!recipientEmail) {
            alert("Email address is required!");
            return;
        }
    
        // Backend API 호출
        fetch(`${SERVER_ROOT}/api/share-diary`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                diaryId,
                recipientEmail,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Diary has been successfully shared!");
                } else {
                    alert(`Failed to share diary: ${data.error}`);
                }
            })
            .catch((error) => {
                console.error("Error sharing diary:", error);
                alert("An error occurred while sharing the diary.");
            });
    };
    

    

    const handleEditClick = (diary) => {
        setIsEditing(true); // 편집 모드로 전환
        setEditedDiaryText(
            isEncoded(diary.diaryText)
                ? decodeURIComponent(diary.diaryText)
                : diary.diaryText
        ); // 기존 내용을 상태에 설정
        // setEditedFiles(diary.files || []); // 첨부 파일 설정
        setEditedFiles(diary.files || []); // 첨부 파일 설정

        // deleteAllFiles(editedFiles, setEditedFiles, language)
    };
    

    const handleSaveEdit = async (diaryId) => {
        try {
            const newFiles = editedFiles.filter(
                (editedFile) => !selectedDiary.files.some(
                    (originalFile) => originalFile.filename === (editedFile.name || editedFile.filename)
                )
            );
    
            const filesToDelete = selectedDiary.files.filter(
                (originalFile) => !editedFiles.some(
                    (editedFile) => (editedFile.name || editedFile.filename) === originalFile.filename
                )
            );

            console.log(`newFiles: ${newFiles}`);
            console.log(`filesToDelete: ${filesToDelete}`);

            console.log(`user.email: ${user.email}`);

            selectedDiary.diaryText  = editedDiaryText;
            
    
            // 업로드할 새 파일 처리
            const formData = new FormData();
            // formData.append("id", diaryId);
            formData.append("diaryText", encodeURIComponent(editedDiaryText));
            formData.append("email", user.email); 
    
            newFiles.forEach((file) => {
                formData.append("files", file, encodeURIComponent(file.name)); // 새 파일 추가
            });
    
            // 서버에 수정된 내용 저장
            const response = await axios.put(
                `${SERVER_ROOT}/api/diaryFileAdd`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { 
                        id: diaryId,  
                        email: user.email, 
                      } 
                }
            );
    
            if (response.status === 200) {
                // 파일 삭제 처리
                // for (const file of filesToDelete) {
                //     console.log(`deleting file: ${file.name || file.filename}`);
                //     await axios.delete(`${SERVER_ROOT}/api/delete-file`, {
                //         params: { filename: file.name || file.filename },
                //     });
                // }
    
                // Update `files` field in the diary table with editedFiles
                // const updatedFiles = editedFiles.map(file => ({
                //     filename: file.name || file.filename,
                //     mimetype: file.mimetype || "unknown",
                // }));

                // await axios.put(`${SERVER_ROOT}/api/diary-update-files-field`, {
                //     id: diaryId,
                //     files: JSON.stringify(updatedFiles),
                // });

                alert(language === "KR" ? "수정이 완료되었습니다." : "Diary updated successfully.");
                setIsEditing(false);
                fetchDiaryList(user.email); // 일기 목록 새로고침
            } else {
                throw new Error("Failed to save diary.");
            }
        } catch (error) {
            console.error("Error saving diary:", error);
            alert(language === "KR" ? "수정을 저장하는 중 문제가 발생했습니다." : "Failed to save diary.");
        }
    };
    
    

    const handleCancelEdit = () => {
        setIsEditing(false);
    };
      

    // 압축 함수
    const compressImage = (imageFile, maxWidth, maxHeight, quality = 0.8) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    // 이미지 크기 조정
                    if (width > maxWidth || height > maxHeight) {
                        if (width / height > maxWidth / maxHeight) {
                            height = (maxWidth / width) * height;
                            width = maxWidth;
                        } else {
                            width = (maxHeight / height) * width;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        (blob) => {
                            resolve(blob);
                        },
                        'image/jpeg', // 압축된 이미지 형식
                        quality // 품질 (0.8 기본)
                    );
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(imageFile);
        });
    };

    const handlePasteRev = async (e) => { 
        // e.preventDefault();

        if (!quillRef.current) return;
        const quill = quillRef.current.getEditor();

        // 현재 커서 위치 저장
        const range = quill.getSelection();
        if (!range) return; 

        const clipboardItems = e.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                e.preventDefault();
                const imageFile = item.getAsFile();
    
                if (imageFile) {
                    try {
                        const compressedImage = await compressImage(imageFile, 400, 400); // 최대 크기 800x800
                        const reader = new FileReader();
                        reader.onload = () => {
                            // const range = quillRef.current.getEditor().getSelection(); // 현재 커서 위치
                            quill.insertEmbed(range.index, 'image', reader.result);

                            // 커서 위치 복원: 삽입된 이미지 뒤로 이동
                            quill.setSelection(range.index + 1); 
                        };
                        reader.readAsDataURL(compressedImage);
                    } catch (error) {
                        console.error('Error compressing image:', error);
                    }
                } else if (item.type === "text/plain") {
                    // 텍스트 처리
                    item.getAsString((text) => {
                        quill.insertText(range.index, text);
                        quill.setSelection(range.index + text.length); 
                    });
                } else if (item.type === "text/html") {
                    // HTML 텍스트 처리
                    item.getAsString((html) => {
                        const tempDiv = document.createElement("div");
                        tempDiv.innerHTML = html;
                        const plainText = tempDiv.innerText; // HTML에서 텍스트만 추출
                        quill.insertText(range.index, plainText);
                        quill.setSelection(range.index + plainText.length); 
                    });
                }
            }
        }

        // adjustScrollToCursor();
    };

    useEffect(() => {
        if (isEditing && quillRef.current) {
          const quillEditor = quillRef.current.getEditor();
          quillEditor.root.addEventListener("paste", handlePasteRev);
      
          // Cleanup 함수: isEditing이 false로 변경되거나 컴포넌트가 언마운트될 때 실행
          return () => {
            quillEditor.root.removeEventListener("paste", handlePasteRev);
          };
        }
      }, [isEditing]); // isEditing 상태에 의존
      

    useEffect(() => {
        const handleResize = () => {
            // iOS 키보드가 사라졌을 때 동작
            if (window.innerHeight === document.documentElement.clientHeight) {
                // 키보드가 닫힌 후 스크롤 복구
                document.body.scrollIntoView({ behavior: 'smooth' });
            }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    
    const handleBlur = () => {
        document.body.scrollIntoView({ behavior: 'smooth' });
    };


    const deleteAllFiles = async (files, setEditedFiles, language) => {
        try {
            for (const file of files) {
                const fileName = file.name || file.filename;
    
                const response = await axios.delete(`${SERVER_ROOT}/api/delete-file`, {
                    params: { fileName },
                });
    
                if (response.status === 200) {
                    console.log(`${fileName} deleted successfully.`);
                } else {
                    console.error(`Failed to delete file: ${fileName}`);
                }
            }
    
            setEditedFiles([]);
            // alert(language === 'KR' ? '모든 파일이 삭제되었습니다.' : 'All files have been deleted.');
        } catch (error) {
            console.error('Error deleting files:', error);
            alert(language === 'KR' ? '파일 삭제 중 문제가 발생했습니다.' : 'An error occurred while deleting the files.');
        }
    };
      
    const renderDiaryDetails = (diary) => {
        

        return (  
            <div className="diary-details"> 
                {isLocalData && <span style={{ color: 'red', fontWeight: 'bold' }}>
                {language === 'EN' ? 'This is the content from local storage.' : '로컬 저장소의 내용입니다.'}
                </span>} 

                {isEditing ? (
                    <div className="DiaryForm"> 
                    <div className="textArea"
                        style={{
                            height: containerHeight,
                            overflow: "visible",
                            transition: "height 0.3s ease", // 높이 변경 시 부드러운 애니메이션
                        }}
                    >  
                        <ReactQuill
                            ref={quillRef}
                            value={editedDiaryText} 
                            onChange={handleTextChange}
                            onChange={setEditedDiaryText}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                            style={{
                                width: "98%",
                                height: quillHeight,
                                marginLeft: "1%",
                                fontSize: "10px",
                                // overflow: "visible", 
                              }}
                        /> 
                    </div>
 
                    <div>
                        <label>
                            {language === 'KR' ? '첨부 파일 추가:' : 'Add Attachments:'}
                            <input
                                type="file"
                                multiple
                                onChange={(e) =>
                                    setEditedFiles([...editedFiles, ...Array.from(e.target.files)])
                                }
                            />
                        </label>
                        <ul>
                            {editedFiles.map((file, index) => (
                                <li key={index} style={{marginLeft: '20px'}}>
                                        <strong
                                        style={{
                                            cursor: 'pointer',
                                            color: 'gray',
                                            textDecoration: 'underline',
                                            fontSize: '12px',
                                            margin: '10px', // 오른쪽에 10px 공간 추가
                                        }}                                            >
                                            {/* {file.name || file.filename}{' '}
                                            {{file.name || file.filename}.replace(/^[^_]+_[^_]+_/, '')} */}
                                            {((file.name || file.filename || '').replace(/^[^_]+_[^_]+_/, ''))}

                                        </strong>
                                    
                                        <button
                                            onClick={async () => {
                                                const fileName = file.name || file.filename;

                                                try {
                                                    // 서버에 파일 삭제 요청
                                                    const response = await axios.delete(`${SERVER_ROOT}/api/diary-delete-file`, {
                                                        params: { 
                                                            id: diary.id,  
                                                            email: user.email, 
                                                            filename: fileName,
                                                            } 
                                                    });

                                                    if (response.status === 200) {
                                                        console.log(`${fileName} deleted successfully.`);

                                                        // 클라이언트 상태에서도 파일 제거
                                                        setEditedFiles((prev) => prev.filter((_, i) => i !== index));
                                                    } else {
                                                        console.error(`Failed to delete file: ${fileName}`);
                                                    }
                                                } catch (error) {
                                                    console.error('Error deleting file:', error);
                                                    alert(language === 'KR' ? '파일 삭제 중 문제가 발생했습니다.' : 'An error occurred while deleting the file.');
                                                }
                                            }}
                                        >
                                            {language === 'KR' ? '삭제' : 'Remove'}
                                        </button>

                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        {/* Save and Cancel buttons */}
                        <button onClick={() => handleSaveEdit(diary.id)}>
                            {language === 'KR' ? '저장' : 'Save'}
                        </button>
                        <button onClick={handleCancelEdit}>
                            {language === 'KR' ? '취소' : 'Cancel'}
                        </button> 
                    </div>
                    </div>
                ) : (
                  <>
                    {/* <h2>{language === 'EN' ? 'Diary Details' : '일기 내용'}</h2> */}
                    <div className='memo-topinfo' >
                        <div style={{width: '200px'}}>
                        {/* <strong>{language !== 'KR' ? 'Date:' : '날짜:'}</strong>  */}
                        {new Date(selectedDiary.selectedDate).toLocaleDateString(
                            language !== 'KR' ? 'en-US' : 'ko-KR'
                        )}
                        {' '}
                        {/* <strong>{language === 'EN' ? 'Time:' : '시간:'}</strong> {new Date(selectedDiary.currentDateTime).toLocaleTimeString()} */}
                        {/* <strong>{language !== 'KR' ? 'Time:' : '시간:'}</strong>  */}
                        {new Date(selectedDiary.currentDateTime).toLocaleTimeString(
                            language !== 'KR' ? 'en-US' : 'ko-KR',
                            { hour: '2-digit', minute: '2-digit' }
                        )}
                        </div>
                        <button
                        className="share-diary-etails-icon"
                        onClick={() => handleShareDiaryClick(diary.id)}
                        style={{ marginRight: '5%', background: 'transparent', color: 'gray'}} // 버튼을 오른쪽 끝으로 정렬
                        > 
                            <TbMailShare style={{width: '20px', height: 'auto'}}/>
                        </button>

                        <button onClick={() => handleEditClick(selectedDiary)} style={{width: '60px'}}>
                            {language === 'KR' ? '편집' : 'Edit'}
                        </button>

                        <button
                        className="close-details-icon"
                        onClick={() => handleCloseDetailClick(diary.id)}
                        style={{ marginRight: '5%', background: 'transparent', color: 'gray'}} // 버튼을 오른쪽 끝으로 정렬
                        >
                            <RiFileReduceLine style={{width: '20px', height: 'auto'}}/>
                        </button>
                        
                    </div>
                    <div className="diary-text">
                        {/* <strong>{language === 'EN' ? 'Text:' : '내용:'}</strong> */}
                        <div dangerouslySetInnerHTML={{ 
                            __html: 
                            isEncoded(selectedDiary.diaryText) 
                                ? decodeURIComponent(selectedDiary.diaryText) 
                                : selectedDiary.diaryText 
                            }}></div>
                    </div>
                    {selectedDiary.voiceFileName && (
                        <> 
                        <audio controls>
                            <source
                                src={`${SERVER_ROOT}/uploads/${selectedDiary.voiceFileName}`}
                                type="audio/mpeg"
                            />
                            {language === 'EN' ? 'Your browser does not support audio playback.' : '브라우저가 음성 재생을 지원하지 않습니다.'}
                        </audio>  
                        <button onClick={handleVoiceFileDownload} style={{ cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }}>
                            {language === 'EN' ? 'Download' : '다운로드'}
                        </button>  
                        </>
                    )}
                    {selectedDiary.photoFileName && (
                        <div className="diary-photo">
                            <img
                                src={`${SERVER_ROOT}/uploads/${selectedDiary.photoFileName}`}
                                alt={language === 'EN' ? 'Diary Photo' : '일기 사진'}
                            />
                        </div>
                    )}
                    {selectedDiary.files && (
                        <div className='attachedFiles'>
                            {/* <strong>{language === 'KR' ? '첨부 파일:' : 'Attached Files:'}</strong> */}
                            <ul style={{width: '100%', height: 'auto', textAlign: 'justify', lineHeight: '1.', padding: '3%'}}>
                                {selectedDiary.files.map((file, index) => {
                                    const fileUrl = `${SERVER_ROOT}/uploads/${file.filename}`;
                                    const fileExtension = (file.filename || file.name).split('.').pop().toLowerCase();

                                    return (
                                        <li key={index} style={{marginLeft: '20px'}}>
                                            <strong
                                            style={{
                                                cursor: 'pointer',
                                                color: 'gray',
                                                textDecoration: 'underline',
                                                fontSize: '12px',
                                                margin: '10px', // 오른쪽에 10px 공간 추가
                                            }}
                                            onClick={() => setShowViewer((prevState) => ({ ...prevState, [index]: !prevState[index] }))}
                                            >
                                                {(file.filename || file.name).replace(/^[^_]+_[^_]+_/, '')}
                                            </strong>
                                            <button
                                            onClick={() => handleFileDownload(file.filename)} // 함수 호출
                                            style={{ 
                                                cursor: 'pointer', color: 'gray', border: 'none', 
                                                fontSize: '12px',
                                                background: 'none' 
                                            }}
                                            >
                                            {<RiFileDownloadLine />}
                                            </button>
                                            {showViewer[index] && (
                                                <div className="viewer-container">
                                                    {['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension) && (
                                                        <img
                                                            src={fileUrl}
                                                            alt={file.filename}
                                                            className="zoomable"
                                                            onTouchStart={(event) => {
                                                                handleDragStart(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchMove={(event) => {
                                                                handleDragMove(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchEnd={(event) => {
                                                                handleDragEnd(event);
                                                                handleTouchEnd(event);
                                                            }}
                                                            style={{ maxWidth: '100%', height: 'auto', margin: '10px 0' }}
                                                        />
                                                    )}
                                                    {['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension) && (
                                                        <audio controls style={{ width: '100%', margin: '10px 0' }}>
                                                            <source 
                                                                src={fileUrl} 
                                                                type={fileExtension === 'm4a' ? 'audio/mp4' : `audio/${fileExtension}`} 
                                                            />
                                                            {language === 'KR'
                                                                ? '브라우저가 오디오 재생을 지원하지 않습니다.'
                                                                : 'Your browser does not support audio playback.'}
                                                        </audio>
                                                    )}
                                                    {['mp4', 'webm', 'ogg', 'mkv', 'mov'].includes(fileExtension) && (
                                                        <video 
                                                            controls 
                                                            className="zoomable"
                                                            onTouchStart={(event) => {
                                                                handleDragStart(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchMove={(event) => {
                                                                handleDragMove(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchEnd={(event) => {
                                                                handleDragEnd(event);
                                                                handleTouchEnd(event);
                                                            }}
                                                            style={{ width: '100%', margin: '10px 0' }}
                                                        >
                                                            <source src={fileUrl} type={`video/${fileExtension}`} />
                                                            {language === 'KR'
                                                                ? '브라우저가 비디오 재생을 지원하지 않습니다.'
                                                                : 'Your browser does not support video playback.'}
                                                        </video>
                                                    )}
                                                    {['pdf'].includes(fileExtension) && (
                                                        <iframe
                                                            src={fileUrl}
                                                            className="zoomable"
                                                            onTouchStart={(event) => {
                                                                handleDragStart(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchMove={(event) => {
                                                                handleDragMove(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchEnd={(event) => {
                                                                handleDragEnd(event);
                                                                handleTouchEnd(event);
                                                            }}
                                                            style={{ width: '100%', height: '700px', margin: '10px 0' }}
                                                            frameBorder="0"
                                                            title={file.filename}
                                                        ></iframe>
                                                    )}
                                                    {!['jpg', 'jpeg', 'png', 'gif', 'webp', 'mp3', 'wav', 'ogg', 'm4a', 'mp4', 'webm', 'ogg', 'pdf', 'mkv', 'mov'].includes(
                                                        fileExtension
                                                    ) && (
                                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                            {file.filename.replace(/^[^_]+_[^_]+_/, '')}  
                                                        </a>
                                                    )}
                                                </div>
                                            )}
                                        </li>


                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <div className='bottom-buttons' style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <button
                        className="close-details-button"
                        onClick={() => {
                            setSelectedDiary(null); 
                            setSelectedDiaryId(null);

                            if (selectedDiaryRef.current) {
                                selectedDiaryRef.current.focus();
                            }
                        }}
                    >
                        {language === 'EN' ? 'Close' : '닫기'}
                    </button>
                    {/* Delete Button */}
                    <button 
                            className="delete-button" 
                            onClick={() => handleDelete(diary.id)}
                        >
                            {language === 'EN' ? 'Delete' : '삭제'}
                        </button>
                    </div>

                  </>
                )}
            </div>
        );
    };

    const renderDiaryItem = (diary) => {
        return (
            <div
                key={diary.id}
                className="diary-item"
                onClick={() => handleDiaryClick(diary.id)}
            >
                <div className="diary-item-content">
                <span>
                    {language === 'EN' 
                        ? new Date(diary.selectedDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                        : new Date(diary.selectedDate).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' })
                    }
                </span>
                    {/* <span dangerouslySetInnerHTML={{ __html: diary.previewText }} /> */}
                    <span>
                        {diary.titleText 
                        ? stripHtmlTags(diary.titleText)
                        : stripHtmlTags(isEncoded(diary.previewText) 
                            ? decodeURIComponent(diary.previewText) 
                            : diary.previewText)}
                    </span>

                    {diary.voiceFileName && (
                        <span>{language === 'EN' ? 'Voice attached' : '음성 첨부됨'}</span>
                    )}
                    {diary.photoFileName && (
                        <span>{language === 'EN' ? 'Photo attached' : '사진 첨부됨'}</span>
                    )}

                    
                </div>
            </div>
        );
    }; 
    
    const adjustScrollToCursor = () => {
        if (!quillRef.current) return;
    
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
    
        if (!range) return;
    
        // 커서의 현재 위치에 대한 DOM 정보 가져오기
        const bounds = quill.getBounds(range.index);
    
        const diaryListContainer = document.querySelector(".diary-list");
        if (diaryListContainer) {
            const scrollOffset = bounds.top + diaryListContainer.scrollTop - 50; // 50px 여유 공간
            diaryListContainer.scrollTo({
                top: scrollOffset,
                behavior: "smooth"
            });
        }
    };

    // useEffect(() => {
    //     if (isEditing) {
    //         adjustScrollToCursor();
    //     }
    // }, [editedDiaryText]);  // editedDiaryText가 변경될 때마다 실행
    
    // useEffect(() => {
    //     if (quillRef.current) {
    //         const quill = quillRef.current.getEditor();
    //         quill.root.addEventListener('keyup', adjustScrollToCursor);
            
    //         return () => {
    //             quill.root.removeEventListener('keyup', adjustScrollToCursor);
    //         };
    //     }
    // }, []);

    
    return (
        
        <div>
            
            <div className="diary-list-container">
            {/* <h1>{language === 'EN' ? 'My Memo & Diary' : '나의 기록'}</h1> */}

                {/* {error && <p className="error-message">{error}</p>}  */}
                
                <div className="diary-list">
                    {diaries.length > 0 ? (
                        diaries.map((diary) => (
                            <> 
                            {selectedDiaryId === diary.id ? (
                                renderDiaryDetails(diary)
                            ) : (
                                renderDiaryItem(diary) 
                            )}
                            </>
                        ))
                    ) : (
                        <p>{language === 'EN' ? 'No diary entries found.' : '일기가 없습니다.'}</p>
                    )}
                </div>

                
            </div>
        </div>
    );
}  


export default Clipboard;
