import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";  
import './ScanPage.css';  

function ScanPage ({ language, user }) {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [scannedImages, setScannedImages] = useState([]); // 스캔된 이미지 목록
  const [currentScan, setCurrentScan] = useState(null); // 현재 스캔 이미지
  const [isScanning, setIsScanning] = useState(false); // 스캔 모드 여부
  const [uploadedFiles, setUploadedFiles] = useState([]); // 업로드된 파일 목록
  const [isOpenCVReady, setIsOpenCVReady] = useState(false); // OpenCV.js readiness flag

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || "http://localhost:3001";


  // Ensure OpenCV.js is ready
  useEffect(() => {
    const handleOpenCVReady = () => {
      if (window.cv) {
        window.cv.onRuntimeInitialized = () => {
          console.log("OpenCV.js is fully loaded and ready.");
          setIsOpenCVReady(true);
        };
      } else {
        console.error("Failed to load OpenCV.js.");
      }
    };

    if (document.readyState === "complete") {
      handleOpenCVReady();
    } else {
      window.addEventListener("load", handleOpenCVReady);
      return () => window.removeEventListener("load", handleOpenCVReady);
    }
  }, []);
  
  // MariaDB에서 업로드된 파일 가져오기
  useEffect(() => {  

    fetch(`${SERVER_ROOT}/api/scanned-files`)
      .then((res) => res.json())
      .then((data) => setUploadedFiles(data))
      .catch((err) => console.error("Error fetching scanned files:", err));
  }, []);

  useEffect(() => {
    if (!window.cv) {
      console.error("OpenCV.js is not loaded!");
      return;
    }
  
    console.log("Waiting for OpenCV.js to initialize...");
    window.cv.onRuntimeInitialized = () => {
      console.log("OpenCV.js initialized successfully.");
      setIsOpenCVReady(true);
    };
  }, []);

  // 스캔 중인 카메라 화면 처리
  useEffect(() => {
    console.log(`isScanning: ${isScanning}`);
    console.log(`isOpenCVReady: ${isOpenCVReady}`);


    if (!isScanning || !isOpenCVReady) return;


    const processCameraFeed = () => {
      if (!webcamRef.current || !canvasRef.current || !window.cv) return;
    
      const video = webcamRef.current.video;
      if (!video || video.readyState !== 4) {
        console.error("Video is not ready.");
        return;
      }
    
      const videoHeight = video.videoHeight;
      const videoWidth = video.videoWidth;
      if (!videoHeight || !videoWidth) {
        console.error("Video dimensions are invalid.");
        return;
      }
    
      const canvas = canvasRef.current;
      canvas.width = videoWidth;
      canvas.height = videoHeight;
    
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
    
      const src = new window.cv.Mat(videoHeight, videoWidth, window.cv.CV_8UC4);
      const dst = new window.cv.Mat();
    
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    
      if (imageData.data.length !== src.data.length) {
        console.error("Mismatch between image data and Mat size.");
        src.delete();
        dst.delete();
        return;
      }
    
      src.data.set(imageData.data);
    
      // Perform edge detection
      window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0);
      window.cv.Canny(dst, dst, 50, 150);
    
      // Find contours
      const contours = new window.cv.MatVector();
      const hierarchy = new window.cv.Mat();
      window.cv.findContours(dst, contours, hierarchy, window.cv.RETR_EXTERNAL, window.cv.CHAIN_APPROX_SIMPLE);
    
      const edges = [];
      for (let i = 0; i < contours.size(); i++) {
        const contour = contours.get(i);
        const approx = new window.cv.Mat();
        window.cv.approxPolyDP(contour, approx, 0.02 * window.cv.arcLength(contour, true), true);
    
        if (approx.rows === 4) {
          const points = [];
          for (let j = 0; j < 4; j++) {
            const x = approx.data32S[j * 2];
            const y = approx.data32S[j * 2 + 1];
            points.push({ x, y });
          }
          edges.push(points);
        }
    
        approx.delete();
      }
    
      edges.forEach((edge) => {
        ctx.beginPath();
        ctx.moveTo(edge[0].x, edge[0].y);
        for (let j = 1; j < edge.length; j++) {
          ctx.lineTo(edge[j].x, edge[j].y);
        }
        ctx.closePath();
    
        ctx.fillStyle = "rgba(0, 255, 0, 0.3)";
        ctx.fill();
    
        ctx.strokeStyle = "rgba(0, 255, 0, 1)";
        ctx.lineWidth = 2;
        ctx.stroke();
      });
    
      // Cleanup
      contours.delete();
      hierarchy.delete();
      src.delete();
      dst.delete();
    };
    

    const interval = setInterval(processCameraFeed, 100); // 100ms마다 실행
    return () => clearInterval(interval);
  }, [isScanning]);

  // 새 스캔 시작
  const handleNewScan = () => {
    setIsScanning(true);
  };

  // 스캔 완료 후 캡처
  const handleCapture = () => {
    const canvas = canvasRef.current;
    const imageDataURL = canvas.toDataURL("image/png");
    setCurrentScan(imageDataURL);
    setIsScanning(false);
  };

  // 스캔 결과 컨펌
  const handleConfirm = () => {
    setScannedImages([...scannedImages, currentScan]);
    setCurrentScan(null);
  };

  // 재스캔
  const handleRescan = () => {
    setCurrentScan(null);
    setIsScanning(true);
  };

  // 서버로 업로드
  const handleUpload = async (image) => {
    const formData = new FormData();
    formData.append("scanFile", image);

    try { 

      const response = await fetch( `${SERVER_ROOT}/api/upload-scan`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setUploadedFiles([...uploadedFiles, data]);
        alert("Upload successful!");
      } else {
        alert("Upload failed.");
      }
    } catch (err) {
      console.error("Error uploading file:", err);
      alert("Error uploading file.");
    }
  };

  return (
    <div className="Home"> 
        <header className="Home-header">
        </header>

        <main>
          <h1>Document Scanner</h1>

          {/* 기존 업로드된 파일 표시 */}
          <div>
            <h2>Uploaded Files</h2>
            <div className='UploadedFiles' >
              {uploadedFiles.map((file) => (
                <div key={file.id} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  <img src={file.file_url} alt={file.file_name} style={{ width: "200px", height: "auto" }} />
                  <p>{file.file_name}</p>
                </div>
              ))}
            </div>
          </div>

          {/* 스캔된 이미지 누적 표시 */}
          <div>
            <h2>Scanned Images</h2>
            <div className='ScannedImages' >
              {scannedImages.map((image, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <img src={image} alt={`Scan ${index + 1}`} style={{ width: "70%", height: "100%" }} />
                  <button onClick={() => handleUpload(image)}>Upload</button>
                </div>
              ))}
            </div>
          </div>

          {/* 스캔 모드 */}
          {isScanning && (
            <div className='webcam'>
              <Webcam
                ref={webcamRef}
                style={{ position: "absolute", top: 0, left: 0 }}
                width="100%"
                height="auto"
              />
              <canvas
                ref={canvasRef}
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "auto" }}
              />
              <button onClick={handleCapture}>Capture</button>
            </div>
          )}

          {/* 스캔 결과 */}
          {currentScan && (
            <div className='scanPreview'>
              <h2>Scan Preview</h2>
              <img src={currentScan} alt="Current Scan" style={{ maxWidth: "100%" }} />
              <button onClick={handleConfirm}>Confirm</button>
              <button onClick={handleRescan}>Rescan</button>
            </div>
          )}

          {/* 새 스캔 버튼 */}
          {!isScanning && !currentScan && (
            <button onClick={handleNewScan}>New Scan</button>
          )}
        </main> 
    </div>
  );
};

export default ScanPage;
