// Home.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay, FaPause, FaRedo } from 'react-icons/fa'; 
import axios from 'axios';  
import './Home.css'; 
// import SplashScreen from './SplashScreen'; // Import SplashScreen
import { useNavigate } from 'react-router-dom';
import he from 'he';
import HomeAdv from './HomeAdv'; // 광고 컴포넌트 불러오기
import MusicPlay from './MusicPlay';
import Homehuggingface from "./Homehuggingface"; // Import the Hugging Face component


function Home({ language, user }) {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [webpageData, setWebpageData] = useState([]);
  const [sloganText, setSloganText] = useState(''); 
  const [tooltipVisible, setTooltipVisible] = useState(null);
  const [tooltipType, setTooltipType] = useState('');
  const [tooltipContent, setTooltipContent] = useState('');   

  const [activeTab, setActiveTab] = useState(''); // 현재 활성 탭을 추적  

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 
   

  const inputRef = useRef(null); // Ref for the editable input
  const userId = user ? user.uid : 'unknown'; 

  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isMobileDevice = isMobile();  
 
  
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch {
      return false;
    }
  }; 

  const handleMouseEnter = (type, message) => {
    
    console.log(`type: ${type}`);
    console.log(`message: ${message}`);

    setTooltipType(type);
    setTooltipContent(message);
    setTooltipVisible(true); 
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  }; 

  const handleTooltipClick = () => {
    if (isMobileDevice) setTooltipVisible(false);
  };

  const getValueByName = (dataArray, name) => {
    const record = dataArray.find(record => record.name === name);
    return record ? record.value : null;
  }; 

  useEffect(() => {
    const tableName = language !== 'KR' ? 'webpage_EN' : 'webpage';
    axios.get(`${SERVER_ROOT}/api/${tableName}`)
      .then((response) => {
        setWebpageData(response.data);
        const sloganRecord = response.data.find(item => item.name === 'App.js_slogun');
        if (sloganRecord) setSloganText(sloganRecord.value);
      })
      .catch((error) => console.error("Error fetching webpage data:", error));
  }, [language]);
  
  function getDomain(url) {
    try {
      const siteUrl = new URL(url);
      const domain = `${siteUrl.hostname}`;
      return domain;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }    
 

  return (
    <div className="Home"> 
        <header className="Home-header">
        </header>

        <main>
        <div className='HomeContainer'
        onClick={(e) => {
          // 클릭한 요소가 mySites의 자식 요소가 아니라면 스크롤 실행
          if (e.target === e.currentTarget) {
            window.scrollTo(0, 0);
          }
        }}>  
          <div className='myHomeTitle'> 
              <div className="home-Greetings" style={{fontSize: '20px'}}>
                {/* <img 
                  src={language === 'KR' ? '/images/나의돌다리.png' : '/images/myDoldari.png'} 
                  alt={language === 'KR' ? '나의 돌다리' : 'My Doldari'} 
                  style={{ width: 'auto', height: '50px' }} 
                  onMouseEnter={() => handleMouseEnter('message',
                    language === 'KR' ? '진화하는 돌다리에서 새로운 웹을 경험하세요.' : 'Enjoy a new Web on evolving Doldari.' )}
                  onMouseOut={handleMouseLeave}
                />   */}
                {language === 'KR' ? '오늘도 당신의 돌다리가 되어 드릴께요!' : 'Doldari is your stepping stone!'}
              </div>
              
              
              <div className="subtitles">
                {/* {user? ( */}
                  <div className="home-tab-buttons"> 
                      
                      <img 
                        src={language !== 'KR' ? "/images/bookmarks.png": "/images/북마크아이콘2.png"} 
                        alt={language !== 'KR' ? 'My Selection' : '북마크'} 
                        onClick={() => {
                          navigate('/homeBookmark'); 
                          setActiveTab('sitelist');
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people."
                        )}
                        onMouseOut={handleMouseLeave} 
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'sitelist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    /> 

                    <img 
                        src={language !== 'KR' ? "/images/learnplay.png": "/images/공부게임아이콘.png"} 
                        alt={language !== 'KR' ? 'Study' : '학습'} 
                        onClick={() => {
                          navigate('/homeLearnPlay');
                          setActiveTab('learnPlaylist');
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people."
                        )}
                        onMouseOut={handleMouseLeave} 
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'learnPlaylist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />
                    
                       

                    <img 
                        src={language !== 'KR' ? "/images/memoDiary.png": "/images/메모일기장.png"} 
                        alt={language !== 'KR' ? "Memo" : '메모일기장'} 
                        onClick={() => {
                          navigate('/homeUtility'); 
                          setActiveTab('utilitylist');
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '이 버튼을 누르면 유명한 사이트 모음이 나옵니다.' : "Click the button and see the well-known sites."
                        )}
                        onMouseOut={handleMouseLeave} 
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'utilitylist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />

                    <img 
                        src={language !== 'KR' ? "/images/shared.png": "/images/공유받은링크.png"} 
                        alt={language !== 'KR' ? 'Invited Contents' : '초대 콘텐츠'} 
                        onClick={() => {
                          navigate('/homeShared');  
                          setActiveTab('sharedlist');
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people."
                        )}
                        onMouseOut={handleMouseLeave} 
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'sharedlist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />
                  </div> 
                
              </div>
          </div>   

          <div className="homeContents">
            
            {/* HomeClipboard 컨테이너 */}
            <div className="home-clipboard-container">
              <img
                src={language !== 'KR' ? "/images/homeclipboard_EN.png": "/images/homeclipboard.png"} 
                alt="Clipboard"
                className="home-clipboard-image"
                onClick={() => navigate('/clipboard')} // 클릭 시 /clipboard 경로로 이동
                style={{
                  cursor: 'pointer', // 버튼처럼 보이도록 커서를 변경
                  width: '350px', // 이미지 크기 설정
                  height: 'auto',
                }}
              />
              <p style={{fontSize: '12px'}}>{language === 'KR' ? '메모 & 일기 쓰기 클립보드' : 'Clipboard for Memo & Diary'}</p> {/* 다국어 지원 */}
            </div>
          </div> 
          
          {/* 음악 플레이어 컴포넌트 */}
          {/* <div className='MusicPlayerContainer'> 
            <MusicPlay />
          </div> */}

          {/* Hugging Face 컨테이너 */}
          <div className="huggingface-section">
              <img 
                src={language === 'KR' ? "/images/homehuggingface.png": "/images/homehuggingface_EN.png"} 
                alt="Clipboard"
                className="home-clipboard-image"
                onClick={() => window.location.href = 'https://huggingface.co/spaces/black-forest-labs/FLUX.1-schnell'}
                // onClick={() => navigate('/homehuggingface')} // 클릭 시 /clipboard 경로로 이동
                style={{
                  cursor: 'pointer', // 버튼처럼 보이도록 커서를 변경
                  width: '350px', // 이미지 크기 설정
                  height: 'auto',
                }}
              />
              <p style={{fontSize: '12px'}}>{language === 'KR' ? 'Huggingface 이미지 생성성' : 'Huggingface image creation'}</p> {/* 다국어 지원 */}

          </div>  

          <div className='advertisement' style={{
                  cursor: 'pointer', // 버튼처럼 보이도록 커서를 변경
                  width: '350px', // 이미지 크기 설정
                  height: 'auto',
                }}>
              <HomeAdv />
            </div> 


          
          
          <div className="TGabyss" style={{marginBottom: '100px'}}>
                <Link to="/intro" style={{ color: 'white', backgroundColor: '#269830', textDecoration: 'none' }}> TG ABYSS Corp. </Link> &copy; All Rights Reserved. &nbsp;  
                <div style={{ color: 'white', backgroundColor: '#269830', textDecoration: 'none' }}>
                {/* {getValueByName(webpageData, 'App.js_ceoText') || "대표"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_ceoName') || "길계태"}, &nbsp; */}
                {getValueByName(webpageData, 'App.js_ceoEmail') || "gategil1149@gmail.com"}
                  {/* | &nbsp;  */}
                {/* {getValueByName(webpageData, 'App.js_phoneText') || "대표번호"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_phoneNumber') || "010-7235-5108"} &nbsp;  */}
                {/* {getValueByName(webpageData, 'App.js_companyAddressText') || "주소"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_companyAddress') || "대전시 유성구 관들4길 3 (관평동)"}   */}
                </div>
                
                {user?.email === 'gategil1149@gmail.com' && (
                  <button
                    className="manage-button"
                    onClick={() => {
                      window.location.href = '/manage'; // Navigate to Manage page
                    }}
                    style={{
                      backgroundColor: '#269830',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      marginLeft: '10px',
                      border: 'none',
                    }}
                  >
                    {language === 'KR' ? '관리' : 'Manage'}
                  </button>
                )}
          </div>     

        </div>
        </main>
    </div>
  );
}

export default Home;
