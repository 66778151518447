import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WordStudyCommon.css';
import { FaPlay, FaPause, FaRedo } from 'react-icons/fa'; 

function WordStudyGRE({ language, user }) {
  const [randomWord, setRandomWord] = useState(''); 
  const [fetchComplete, setFetchComplete] = useState('false');
  const [wordList, setWordList] = useState([]); // 단어 목록 상태 추가
  const [wordIdx, setWordIdx] = useState(0);
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(3);
  const [randomFetch, setRandomFetch] = useState(false); // 
  const [autoStreaming, setAutoStreaming] = useState(false); //  
  const [timer, setTimer] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false); 
  const [gameTime, setGameTime] = useState(300);
  const [GREwordId, setGREwordId] = useState(0);
  const [GREwordIdStart, setGREwordIdStart] = useState(1);
  const [gameOver, setGameOver] = useState(false); // New state for game over
  const [showDescription, setShowDescription] = useState(false); // 설명 표시 여부
  const [showMeaning, setShowMeaning] = useState(false); // 설명 표시 여부
  const [isOK, setIsOk] = useState(false);
  const [volume, setVolume] = useState(0.5); // Default volume (50%)
  const [isLooping, setIsLooping] = useState(false); // Looping state
  const [isMusicPlaying, setIsMusicPlaying] = useState(true); // State to track if music is playing
  const [searchWord, setSearchWord] = useState('');


  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

  // 컴포넌트 로드 시 음성합성 실행
  const [music] = useState(new Audio('/resources/good-morning-142550.mp3')); // Initialize audio
   
  const storedUser = JSON.parse(localStorage.getItem('uk_user'));
  if (!user) {
      user = storedUser;
  }

  // Fetch random word from the database
  const fetchRandomWord = async (newGREwordID) => { 

    let response; // response 변수를 if-else 블록 바깥에 선언
    try {  
        // response = await axios.get(`${SERVER_ROOT}/api/GREwords_en`); 
        // const newGREwordID = GREwordId + 1;

        let randomFetchFlag = 0;
        if (randomFetch === true){
          randomFetchFlag = 1;
        } 

        response = await axios.get(`${SERVER_ROOT}/api/COMMONwords_en`, { 
          params: { 
            wordId: `${newGREwordID}`, 
            table: 'GREwords_en',  
            randomFetchFlag: `${randomFetchFlag}`,
            numberOfWords: 100
          } 
        });

        const fetchedWords = response.data; // 응답 데이터가 배열인지 확인
        console.log(`Fetched words:`, fetchedWords);

        if (Array.isArray(fetchedWords)) {
          setWordList((prevList) => [...prevList, ...fetchedWords]); // 기존 리스트에 추가
          setFetchComplete('true');
          
          // 첫 번째 단어를 상태로 설정
          if (fetchedWords.length > 0) {
            setRandomWord(fetchedWords[0]); // 첫 번째 단어를 현재 단어로 설정
            setGREwordId(fetchedWords[0].id); // 첫 번째 단어의 ID로 설정
          }
        } else {
          console.error('Unexpected response format. Expected an array of words.');
        }

        // const newWord = response.data;  
        // console.log(`newly fetched word: ${newWord.word}`);
        
        // setWordList((prevList) => [...prevList, newWord]); // 단어를 배열에 추가
        // setFetchComplete('true');
 
        // setRandomWord(newWord);    
        // setGREwordId(newWord.id);  
        

    } catch (error) {
      console.error('Error fetching random word:', error);
    }
  };

  // Play sound effect
  const playSound = (soundType) => {
    let audio;
    switch (soundType) {
      case 'start':
        audio = new Audio('/sound/gamestart.m4a'); // Replace with actual sound file
        break;
      case 'correct':
        audio = new Audio('/sound/correct.m4a'); // Replace with actual sound file
        break;
      case 'pass':
        audio = new Audio('/sound/pass.m4a'); // Replace with actual sound file
        break;
      case 'gameOver':
        audio = new Audio('path/to/game-over-sound.mp3'); // Replace with actual sound file
        break;
      default:
        return;
    }
    audio.play();
  };

  const getVoices = () => {
    const voices = window.speechSynthesis.getVoices();
    // console.log('Available voices:', voices);
  };
  getVoices();

  
  window.speechSynthesis.onvoiceschanged = () => {
    const voices = window.speechSynthesis.getVoices();
    console.log('Voices loaded:', voices);
  };

  
  const playVoiceSynthesizer = (text) => {
    // const text = `
    //     게임 시작 버튼을 클릭해주세요.
    //     `;
  
    // 이전 음성을 취소
    window.speechSynthesis.cancel();

    const utterance = new SpeechSynthesisUtterance(text);
    if (language === 'KR') {
        utterance.lang = 'ko-KR'; // 한국어 설정
    } else {
        utterance.lang = 'en-US'; // en-US, en-GBm en-AUm en-CA, en_IN
    }
    utterance.rate = 1; // 속도 (1은 기본 속도)
    utterance.pitch = 1; // 음조 (1은 기본 음조)

    // 음성 재생
    window.speechSynthesis.speak(utterance);
  }

  const playVoiceSynthesizer_en = (text) => {
    // 이전 음성을 취소
    window.speechSynthesis.cancel();

    // 정규식을 사용하여 문장을 분리
    const sentences = text.match(/[^.!?]*[.!?]/g) || [text]; // 문장 끝을 기준으로 나눔
    const chunks = []; 

    // 4개 문장씩 청크로 묶기
    for (let i = 0; i < sentences.length; i += 4) {
        chunks.push(sentences.slice(i, i + 4).join(" ")); // 4문장씩 결합
    }

    // 음성을 순차적으로 재생
    const playChunk = (index) => {
        if (index >= chunks.length) return; // 모든 청크가 재생되었으면 종료

        const utterance = new SpeechSynthesisUtterance(chunks[index]);
        const voices = window.speechSynthesis.getVoices();
        const selectedVoice = voices.find(voice => voice.name === 'Samantha') || voices.find(voice => voice.lang === 'en-US');
        if (selectedVoice) {
            utterance.voice = selectedVoice;
        }

        utterance.lang = 'en-US';
        utterance.rate = 1;
        utterance.pitch = 1;

        utterance.onend = () => {
            playChunk(index + 1); // 현재 청크가 끝나면 다음 청크 재생
        };

        utterance.onerror = (e) => {
            console.error('Error in speech synthesis:', e);
        };

        window.speechSynthesis.speak(utterance);
    };

    // 첫 번째 청크부터 재생 시작
    playChunk(0);
  }; 

  const playVoiceSynthesizer_kr = (text) => {
    // 이전 음성을 취소
    window.speechSynthesis.cancel();

    // 정규식을 사용하여 문장을 분리
    const sentences = text.match(/[^.!?]*[.!?]/g) || [text]; // 문장 끝을 기준으로 나눔
    const chunks = []; 

    // 4개 문장씩 청크로 묶기
    for (let i = 0; i < sentences.length; i += 4) {
        chunks.push(sentences.slice(i, i + 4).join(" ")); // 4문장씩 결합
    }

    // 음성을 순차적으로 재생
    const playChunk = (index) => {
        if (index >= chunks.length) return; // 모든 청크가 재생되었으면 종료

        const utterance = new SpeechSynthesisUtterance(chunks[index]);
        const voices = window.speechSynthesis.getVoices();
        const selectedVoice = voices.find(voice => voice.name === 'Samantha') || voices.find(voice => voice.lang === 'ko-KR');
        if (selectedVoice) {
            utterance.voice = selectedVoice;
        }

        utterance.lang = 'ko-KR';
        utterance.rate = 1.5;
        utterance.pitch = 1.2;

        utterance.onend = () => {
            playChunk(index + 1); // 현재 청크가 끝나면 다음 청크 재생
        };

        utterance.onerror = (e) => {
            console.error('Error in speech synthesis:', e);
        };

        window.speechSynthesis.speak(utterance);
    };

    // 첫 번째 청크부터 재생 시작
    playChunk(0);
}; 


  // const playVoiceSynthesizer_kr = (text) => {
  //   // const text = `
  //   //     게임 시작 버튼을 클릭해주세요.
  //   //     `;
  
  //   // 이전 음성을 취소
  //   window.speechSynthesis.cancel();

  //   const utterance = new SpeechSynthesisUtterance(text); 
  //   utterance.lang = 'ko-KR'; // en-US, en-GBm en-AUm en-CA, en_IN 
  //   utterance.rate = 1; // 속도 (1은 기본 속도)
  //   utterance.pitch = 1; // 음조 (1은 기본 음조)

  //   // 음성 재생
  //   window.speechSynthesis.speak(utterance);
  // }

  const startGame = () => { 

    setCountdown(3); // 카운트다운 초기화
    setIsPlaying(true);
    setTimer(gameTime); // 게임 타이머 초기화
    playVoiceSynthesizer_en('GRE word study is starting soon!'); // 음성 안내
  
    const countdownInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval); // 카운트다운 종료
  
          setTimeout(() => {
                startTimer(); // 타이머 시작
            }, 2000); // 첫 단어를 2초간 표시

          // 첫 번째 단어를 한 번만 가져오기 
          // setGREwordId(GREwordId+1); 

          

  
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    console.log(`wordIdx: ${wordIdx}`);
    console.log(`wordList.length: ${wordList.length}`);

    setGREwordId(GREwordIdStart-1);
    fetchRandomWord(GREwordIdStart); 
  };
  
  
  
  // Start the timer when countdown finishes
  const startTimer = () => {
    const timerInterval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime <= 0) { 
          clearInterval(timerInterval);
          endGame(); // End the game when time runs out
          return 0; // 타이머를 0으로 설정
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  

  const handleNext = () => {
    setScore((prev) => prev + 1);
    
    console.log(`wordIdx 1: ${wordIdx}`);
    console.log(`wordList.length 1: ${wordList.length}`); 
    console.log(`word at wordId = ${wordIdx}: ${wordList[wordIdx].word}`);
    console.log(`word at wordId = ${wordList.length}-1: ${wordList[wordList.length-1].word}`);

    const newIdx = wordIdx + 1; 
    
    // If we are at the last word, fetch a new random word
    if (newIdx === wordList.length) {  
        fetchRandomWord(GREwordId+1);
    }; 

    setWordIdx(newIdx); 
    
    const newWord = wordList[wordIdx + 1];
    if (newWord) {
        setRandomWord(newWord); // Update the random word state
        setGREwordId(newWord.id); // Update the GREwordId state
    }   
    // playSound('correct');
    // playVoiceSynthesizer_en('Great!');
  };

  
  const handlePrev = () => {
    // setScore((prev) => prev - 1); 
    setScore((prev) => (prev > 0 ? prev - 1 : 0));

    console.log(`wordIdx: ${wordIdx}`);
    console.log(`wordList.length: ${wordList.length}`); 

    const newIdx = wordIdx > 0 ? wordIdx - 1 : 0; 

    setWordIdx(newIdx);

    const newWord = wordList[newIdx];
    if (newWord) {
        setRandomWord(newWord); // Update the random word state
        setGREwordId(newWord.id); // Update the GREwordId state
    }   
    // playSound('correct');
    // playVoiceSynthesizer_en('Great!');
  };

  // Handle pass
  const handlePass = () => {
    // let idx = wordIdx;

    console.log(`wordIdx 1: ${wordIdx}`);
    console.log(`wordList.length 1: ${wordList.length}`);
    

    const newIdx = wordIdx + 1; 
    
    
    // If we are at the last word, fetch a new random word
    if (newIdx === wordList.length) {  
        fetchRandomWord(GREwordId+1);
    }; 

    setWordIdx(newIdx); 
    
    const newWord = wordList[wordIdx + 1];
    if (newWord) {
        setRandomWord(newWord); // Update the random word state
        setGREwordId(newWord.id); // Update the GREwordId state
    }   
  };

  

useEffect(() => {
  console.log(`autoStreaming: ${autoStreaming}`);
  console.log(`gameOver: ${gameOver}`);
  console.log(`wordIdx: ${wordIdx}`);
  console.log(`GREwordId: ${GREwordId}`);
 
    console.log(`wordList.length: ${wordList.length}`);

  if (autoStreaming === true && !gameOver && isPlaying) {
    const timer = setTimeout(() => {
      const newIdx = wordIdx + 1;

      // If we are at the last word, fetch a new random word
      if (newIdx === wordList.length) {
        fetchRandomWord(GREwordId + 1);
      }

      setWordIdx(newIdx);

      const newWord = wordList[wordIdx + 1];
      if (newWord) {
        setRandomWord(newWord); // Update the random word state
        setGREwordId(newWord.id); // Update the GREwordId state
      }
    }, 2000); // 1000ms = 1초마다 반복

    return () => clearTimeout(timer); // Clean up the timeout on unmount or when `autoStreaming` changes
  }
}, [autoStreaming, wordIdx, wordList, GREwordId, gameOver, isPlaying]); // 필요한 상태들을 의존성 배열에 포함




  // End game and show score
  const endGame = () => {
    if (gameOver) return; // 이미 게임이 종료되었다면 실행하지 않음

    setIsPlaying(false);
    setGameOver(true); // Set game over to true
    // playSound('gameOver');
    playVoiceSynthesizer_en('Good Job!');
  };

  // Reset game
  const resetGame = () => {
    // const instructionsText = `This game is a fun GRE word guessing quiz. Click on the words and examples to hear native speaker pronunciation. Good luck!`;
    //   playVoiceSynthesizer_en(instructionsText); 
    //   music.play();  

    setRandomWord('');
    setWordList([]); // 단어 목록 초기화
    setWordIdx(0);
    setScore(0);
    setCountdown(3);
    setTimer(0);
    setIsPlaying(false);
    setGameOver(false); // Reset game over state 
    readInstructions();
  };

  // 안내글 추가 섹션
  const gameInstructions = () => {
    // Define the text for voice synthesis
    const text = 'This is a fun GRE word guessing quiz. It can serves as an English vocabulary tool for self-study. Click on the words and examples to hear native speaker pronunciation. Good luck!';

    // Call the voice synthesizer function
    // playVoiceSynthesizer_en(text); 
    };


 // Use useEffect to call gameInstructions on component load
//  useEffect(() => {
//     if (!isOK) {
//       gameInstructions();
//     }
//   }, [isOK]);
  
  // 음성합성 함수
const readInstructions = () => {
    
    let text = '';
    if (language === 'KR') {
        text = `게임 시작 버튼을 클릭해주세요.`;
        playVoiceSynthesizer_kr(text);
    } else {
        text = `Click the start button please.`;
        playVoiceSynthesizer_en(text);
    }
    
  };

  

  useEffect(() => {
    // gameInstructions(); 
    // music.play();  
  }, []); // 빈 의존성 배열로 컴포넌트 로드 시 한 번만 실행

  // Play audio
  const playMusic = () => {
    music.play();
  };
 // Pause audio
 const pauseMusic = () => {
    music.pause();
  };

  // Toggle looping
//   const toggleLoop = () => {
//     const newLoopState = !isLooping;
//     setIsLooping(newLoopState);
//     music.loop = newLoopState; // Enable or disable looping
//   };

  const togglePlayPause = () => {
    if (isMusicPlaying) {
      music.pause();
    } else {
      music.play();
    }
    setIsMusicPlaying(!isMusicPlaying); // Toggle the playing state
  }; 

  // Update volume
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    music.volume = newVolume; // Update audio volume
  };

  const handleCheckboxRandomFetch = (e) => {
    setRandomFetch(e.target.checked);
  };

  const handleCheckboxAutoStreaming = (e) => {
    setAutoStreaming(e.target.checked); 
  };

  const handleCheckboxChangeMeaning = (e) => {
    setShowMeaning(e.target.checked);
  };

  const handleCheckboxChange = (e) => {
    setShowDescription(e.target.checked);
  };

  // 카운트다운 종료 후 음성 안내
  useEffect(() => {
    if (countdown === 0 && randomWord?.word) {
        playVoiceSynthesizer_en(randomWord.word); // 단어를 음성으로 안내
    }
  }, [countdown, randomWord]);

  useEffect(() => {
    if (isOK && !isPlaying && !gameOver) {
      const instructionsText = `This is a fun GRE word guessing quiz. Click on the words and examples to hear native speaker pronunciation. Good luck!`;
      playVoiceSynthesizer_en(instructionsText); 
      music.play();  
    }
     
  }, [isOK, isPlaying, gameOver, music]);
  
  useEffect(() => {
    if (isPlaying) {
      music.pause();
      music.currentTime = 0; // Reset the music
    }
  }, [isPlaying, music]);


  // 서버 요청 함수: 입력된 단어로 레코드 검색
  const searchWordInDatabase = async () => {
    try {
      const response = await axios.get(`${SERVER_ROOT}/api/search_word`, {
        params: {
          table: 'GREwords_en',
          word: searchWord,
        },
      });

      // console.log(`searching word: ${searchWord}`);

      if (response.data && response.data.id) {
        setGREwordIdStart(response.data.id); // 검색된 단어의 ID 설정
        // alert(`Found word ID: ${response.data.id}`);
      } else {
        alert('Word not found in the database.');
      }
    } catch (error) {
      console.error('Error searching word in database:', error);
      alert('An error occurred while searching the word.');
    }
  };

  return (  
    
    <div className="HeadsUp">

    
      {!isOK && (   
            <div className="game-instructions">
            <h2>학습 안내</h2> 
            
            <p>
                이 어플은 재미있는 GRE 단어 맞추기 퀴즈 어플입니다.
                혼자서 공부를 할 수 있는 영어단어장이기도 해요. 단어와 영어문장을 클릭하시면 음성을 들을 수 있어요.
            </p> 
                <img width='50%' height='50%' src='/images/WordStudyGRE.png'></img>
                <button onClick={() => setIsOk(true)}>OK</button>  
            </div>  
      )}

      {isOK && !isPlaying && !gameOver && (
        <>
        <div className="music-player"> 
          <div className="volume-control">
            <label htmlFor="volume-control">Volume:</label>
            <input
              id="volume-control"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
            <span>{Math.round(volume * 100)}%</span>
            <button onClick={togglePlayPause} className="icon-button">
                {isMusicPlaying ? <FaPause size={12} /> : <FaPlay size={12} />}
            </button>
            {/* <button onClick={toggleLoop} className="icon-button">
                <FaRedo size={12} color={isLooping ? 'green' : 'black'} />
            </button> */}
          </div>
        </div>
        <div className="start-screen">
          <h2>Get Ready!</h2>
          <div>
            <label>
                <input
                type="checkbox"
                checked={randomFetch}
                onChange={handleCheckboxRandomFetch}
                />
                랜덤 순서 학습
            </label>
            <label>
                <input
                type="checkbox"
                checked={autoStreaming}
                onChange={handleCheckboxAutoStreaming}
                />
                연속 재생
            </label>
          </div>
          <div>
            <label htmlFor="gameTime">Set Game Time (seconds): </label>
            <input
            type="number"
            id="gameTime"
            value={gameTime.toString()} // 문자열로 변환하여 앞의 0을 제거
            onChange={(e) => {
                const inputValue = e.target.value;
                // 앞의 0 제거 및 빈 값 처리
                const sanitizedValue = inputValue.startsWith('0') && inputValue !== '0'
                ? inputValue.replace(/^0+/, '') // 앞의 0 제거
                : inputValue;

                setGameTime(sanitizedValue === '' ? 0 : Number(sanitizedValue));
            }}
            style={{ width: "50px", padding: "5px", marginTop: '10px' }}
            />
          </div>
          <div> 
            <label htmlFor="GREwordId">Start from word ID: </label>
            <input
            type="number"
            id="GREwordIdStart"
            value={GREwordIdStart.toString()} // 문자열로 변환하여 앞의 0을 제거
            onChange={(e) => {
                const inputValue = e.target.value;
                // 앞의 0 제거 및 빈 값 처리
                // const sanitizedValue = inputValue.startsWith('0') && inputValue !== '0'
                // ? inputValue.replace(/^0+/, '') // 앞의 0 제거
                // : inputValue;

                setGREwordIdStart(Number(inputValue));
            }}
            style={{ width: "100px", padding: "5px", marginTop: '10px' }}
            />
          </div>

          <div>
            <label htmlFor="searchWord">First word: </label>
            <input
              type="text"
              id="searchWord"
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder="Enter a word"  
              style={{ width: "150px", padding: "5px", marginRight: '10px' }}
            />
            <button onClick={searchWordInDatabase} style={{marginTop: '10px'}}>Apply</button>
          </div>
          
            
        
          <div className='startGameButton'>
            <button onClick={startGame}>Start Game</button>
          </div>
        </div>
        </>
      )}



        {isPlaying && (
        <div className="game-screen">
            {/* 점수 및 타이머 */}
            {/* <div className="score"> Word ID: {randomWord.id}, Time Left: {timer}s, Score: {score} </div> */}
            <div className="score"> Word ID: {randomWord.id}, Time Left: {timer}s </div>

            {countdown > 0 ? (
            // 카운트다운 표시
            <h2>{countdown}</h2>
            ) : (
            <>
                {/* 단어 및 세부 정보 표시 */} 

                {/* <h1 className="word">{randomWord.word || 'No word available'}</h1>  */}
                <h1 
                    className="word-style" 
                    onClick={() => playVoiceSynthesizer_en(randomWord.word)}
                    onLoad={() => playVoiceSynthesizer_en(randomWord.word)}
                    >
                    {randomWord.word}
                </h1>

                { (showMeaning || showDescription) && ( 
                  <div className="meaning">
                    <p  
                        onClick={() => playVoiceSynthesizer_kr(randomWord.meaning)} 
                    > 『{randomWord.part_of_speech || 'No part of speech available'}』   {randomWord.meaning || 'No meaning available'}
                    </p>
                  </div>
                )}


                <div className="checkbox-container">
                    <label>
                        <input
                        type="checkbox"
                        checked={showMeaning}
                        onChange={handleCheckboxChangeMeaning}
                        />
                        뜻 보기
                    </label>
                    <label>
                        <input
                        type="checkbox"
                        checked={showDescription}
                        onChange={handleCheckboxChange}
                        />
                        설명보기
                    </label>
                    <label>
                      <input
                      type="checkbox"
                      checked={autoStreaming}
                      onChange={handleCheckboxAutoStreaming}
                      />
                      연속 재생
                  </label>
                </div>

                

                {showDescription && (
                  <> 

                <div className="word-details"> 
                    {/* <p className="part-of-speech">『{randomWord.part_of_speech || 'No part of speech available'}』</p> */}
                    
                    <p className="example1" 
                    onClick={() => playVoiceSynthesizer_en(randomWord.example1)}
                    >
                        <strong>예문: </strong> {randomWord.example1 || 'No example available'} 
                    {' ('}
                        {randomWord.example1_kr || 'No example available'} 
                    {')'}
                    </p> 
                    <p className="synonym">
                        <strong>유사어: </strong> {randomWord.synonym || 'No synonyms available'}
                    </p>  
                    <p className="explanation_en"
                    onClick={() => playVoiceSynthesizer_en(randomWord.explanation_en)}
                    >
                        <strong>설명: </strong> {randomWord.explanation_en || 'No example available'}
                        {' ('} {randomWord.explanation_kr || 'No example available'}
                        {')'}
                    </p>
                    <a
                        href={`https://en.dict.naver.com/#/search?query=${encodeURIComponent(randomWord.word)}&range=all`}
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        {language === 'KR' ? '네이버사전' : 'Naver dictionary'}
                    </a>
                </div>
                </>
                )}


                {/* 게임 컨트롤 버튼 */}
                <div className="game-controls">
                <button onClick={handlePrev}>Prev</button>
                <button onClick={handleNext}>Next</button>
                <button onClick={handlePass}>Pass</button>
                 
                </div>
            </>
            )}

            
        </div>
        )}


      {gameOver && (
        <div className="game-over-screen">
          <h2>Game Over</h2>
          <div className="final-score">Your Score: {score}</div>
          <button className="reset-button" onClick={resetGame}>
            Restart
          </button>
 
          <div className="record-list" >
            <h3>{language === 'KR' ? '퀴즈에 나온 단어들' : 'Words from the quiz '}</h3>
                <table>
                  {/* <thead>
                    <tr>
                      <th> {language === 'KR' ? "단어" : "Word"} </th>
                      <th> {language === 'KR' ? "설명" : "Description"}  </th> 
                    </tr>
                  </thead> */}
                  <tbody> 
                    {wordList.map((item, index) => (
                      <tr key={index}>
                        <td>
                            <span 
                                className="word-style-summary" 
                                onClick={() => playVoiceSynthesizer_en(item.word)}
                                >
                                {item.word}
                            </span>
                            <p  className="part-of-speech"
                                onClick={() => playVoiceSynthesizer_kr(randomWord.meaning)} 
                            > 『{randomWord.part_of_speech || 'No part of speech available'}』   {randomWord.meaning || 'No meaning available'}
                            </p>
                            {/* <p className="part-of-speech"><strong>품사:</strong> 『{item.part_of_speech || 'No part of speech available'}』</p> */}
                            {/* <p className="meaning-summary"
                                onClick={() => playVoiceSynthesizer_kr(item.meaning)}
                            ><strong>뜻:</strong>{item.meaning || 'No meaning available'}</p> */}
                            <p className="example1"
                            onClick={() => playVoiceSynthesizer_en(item.example1)}
                            >
                                <strong>예문:</strong> {item.example1 || 'No example available'} 
                                {' ('}
                                <strong>번역:</strong> {item.example1_kr || 'No example available'} 
                                {')'}
                            </p>
                            <p className="example2" 
                            onClick={() => playVoiceSynthesizer_en(item.example2)}
                            >
                            <strong>예문:</strong> {item.example2 || 'No example available'} 
                                {' ('}
                            <strong>번역:</strong> {item.example2_kr || 'No example available'} 
                                {')'}
                            </p>
                            <p className="synonym">
                                <strong>유사어:</strong> {item.synonym || 'No synonyms available'}
                            </p>  
                            <p className="explanation_en"
                            onClick={() => playVoiceSynthesizer_en(item.explanation_en)}
                            >
                                <strong>설명:</strong> {item.explanation_en || 'No example available'}
                            </p>
                            <p className="explanation_kr">
                                <strong>번역:</strong> {item.explanation_kr || 'No example available'}
                            </p>
                            <a
                            href={`https://en.dict.naver.com/#/search?query=${encodeURIComponent(item.word)}&range=all`}
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            {language === 'KR' ? '네이버사전' : 'Naver dictionary'}
                            </a>
                        </td>
                        </tr>
                    
                    ))}
                  </tbody>
                </table>
                
          </div>
        </div>
      )} 
    </div>
    
  );
}

export default WordStudyGRE;
