// HomeLearnPlay.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';  
import './HomeCommon.css';
import { BiTrash } from 'react-icons/bi';
import { BiCopy } from "react-icons/bi";
import { BiShare, BiShareAlt, BiMoveHorizontal, BiMoveVertical } from "react-icons/bi"; // Importing an icon for the share button
 
import { useNavigate } from 'react-router-dom';
import he from 'he';


function HomeLearnPlay({ language, user }) {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [webpageData, setWebpageData] = useState([]);
  const [sloganText, setSloganText] = useState('');
  const [defaultSites, setDefaultSites] = useState([]); // default-sites 컨테이너에 사용할 사이트 목록
  const [sitelist, setSitelist] = useState([]);
  const [learnPlaylist, setLearnPlaylist] = useState([]);
  const [sharedList, setSharedList] = useState([]); // New state for shared list
  const [utilitylist, setUtilitylist] = useState([]);  
  const [newSite, setNewSite] = useState({ name: '', userEmail: '', url: '' });
  const [tooltipVisible, setTooltipVisible] = useState(null);
  const [tooltipType, setTooltipType] = useState('');
  const [tooltipContent, setTooltipContent] = useState('');
  const [editingTitleId, setEditingTitleId] = useState(null);
  const [editingTitleUrl, setEditingTitleUrl] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
  // const [copySuccess, setCopySuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); 
  const [tableName, setTableName] = useState(null);
  const [siteToDelete, setSiteToDelete] = useState(null);

  const [showThumbnailPopup, setShowThumbnailPopup] = useState(false);
  const [newThumbnailUrl, setNewThumbnailUrl] = useState('');
  const [siteToEdit, setSiteToEdit] = useState(null);

  const [touchTimeoutId, setTouchTimeoutId] = useState(null);


  const [toplevelActiveTab, settoplevelActiveTab] = useState('utilitylist'); // 현재 활성 탭을 추적 

  const [activeTab, setActiveTab] = useState('utilitylist'); // 현재 활성 탭을 추적
  

  const [showShareOverlay, setShowShareOverlay] = useState(false);
  const [invitedUserEmail, setInvitedUserEmail] = useState('');
  const [sharedSite, setSharedSite] = useState(null); // To hold the site data for sharing
  const [shareOption, setShareOption] = useState(''); // To manage checkbox options

  const [showOverlay, setShowOverlay] = useState(false);
  const [clipboardUrl, setClipboardUrl] = useState('');

  const [isVertical, setIsVertical] = useState(false); // 초기값: 세로 정렬
  const newCardRef = useRef(null); // 새 카드의 Ref 생성

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 
  
  let INPUT_UPSLIDE = 350;

  const inputRef = useRef(null); // Ref for the editable input
  const userId = user ? user.uid : 'unknown'; 

  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isMobileDevice = isMobile();
 
  // 컴포넌트가 마운트된 후 실행되는 `useEffect`
    useEffect(() => {
        setActiveTab('learnPlaylist');
        fetchLearnPlayList(); // Call the function to fetch shared list data
    }, []); // 빈 의존성 배열은 컴포넌트가 마운트될 때만 실행됨

  // default-sites 데이터 가져오기
  useEffect(() => {
    axios.get(`${SERVER_ROOT}/api/sitelist/unknown`) // user=null에 해당하는 사이트 목록 불러오기
      .then((response) => {
        setDefaultSites(response.data);
      })
      .catch((error) => console.error("Error fetching default sites:", error));
  }, []);

  
  const fetchSitelist = () => {
    axios.get(`${SERVER_ROOT}/api/sitelist/${userId}`)
      .then((response) => {
        const updatedSitelist = response.data.map(site => {
            if (!site.title || site.title.trim() === '' || site.title === 'NULL') {
                const url = new URL(site.url);
                site.title = url.hostname.replace('www.', ''); // 기본적으로 도메인 이름을 title로 설정
            } 
            return site;
        });

        const sortedData = updatedSitelist.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
  
          if (dateB - dateA !== 0) {
            // 날짜 기준으로 정렬
            return dateB - dateA;
          }
          // 방문 수 기준으로 정렬
          return b.visitCount - a.visitCount;
        });

        setSitelist(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching sitelist:", error);
        setSitelist([]); // Reset sitelist to empty array on error
      });
  };

    
   // Function to fetch shared list based on the user email or "To all"

   const fetchUtilitylist = () => {
    // if (!user || !user.email) return;

    let userEmail = 'To all';
    if(user) { 
      userEmail = user.email;
    }

    
    axios.get(`${SERVER_ROOT}/api/utilitylist`, {
      params: { invitedUserEmail: userEmail }
    })
    .then(response => {

      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        if (dateB - dateA !== 0) {
          // 날짜 기준으로 정렬
          return dateB - dateA;
        }
        // 방문 수 기준으로 정렬
        return b.visitCount - a.visitCount;
      });


      setUtilitylist(sortedData);
    })
    .catch(error => console.error("Error fetching memoDiary list:", error));
  };

   const fetchSharedList = () => {
    // if (!user || !user.email) return;

    let userEmail = 'To all';
    if(user) { 
      userEmail = user.email;
    }

    
    axios.get(`${SERVER_ROOT}/api/sharedlist`, {
      params: { invitedUserEmail: userEmail }
    })
    .then(response => {

      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        if (dateB - dateA !== 0) {
          // 날짜 기준으로 정렬
          return dateB - dateA;
        }
        // 방문 수 기준으로 정렬
        return b.visitCount - a.visitCount;
      });


      setSharedList(sortedData);
    })
    .catch(error => console.error("Error fetching shared list:", error));
  };

  const fetchLearnPlayList = () => {
    axios.get(`${SERVER_ROOT}/api/learnPlaylist/`)
      .then(response => { 
        setLearnPlaylist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching fetchLearnPlayList:", error); 
      });
  };

  // Fetch sitelist and sharedlist on mount
  useEffect(() => {
    fetchSitelist();
    fetchSharedList();
    fetchLearnPlayList();
    fetchUtilitylist();
  }, [userId, user?.email]);



  // 마우스 우클릭 이벤트 핸들러
  const handleRightClickToAddSite = async (e) => { 
    e.preventDefault(); // Prevent the default context menu
    try {
      const clipboardText = await navigator.clipboard.readText();
      if (isValidUrl(clipboardText)) {
        setClipboardUrl(clipboardText); // Set the clipboard URL for display
        setShowOverlay(true); // Show the overlay
      } else {
        alert(language !== 'KR' ? 'Invalid URL in clipboard!' : '클립보드에 유효한 URL이 없습니다!');
      }
    } catch (error) {
      console.error('Error reading clipboard:', error);
      alert(language !== 'KR' ? 'Could not read clipboard!' : '클립보드를 읽을 수 없습니다!');
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch {
      return false;
    }
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation(); // Prevent clicks inside the overlay from closing it
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setClipboardUrl('');
  };

  const addNewSite = () => {
    if (!newSite.url) {
      alert("사이트 URL을 입력해주세요.");
      return;
    }
  
    newSite.name = user.displayName;
    newSite.userEmail = user.email;
  
    axios.post(`${SERVER_ROOT}/api/sitelist`, { userId: userId || 'unknown', ...newSite })
    .then((response) => {
      const addedSite = response.data;
      // title이 비어 있거나 의미 없는 값일 때 대체하기
      if (!addedSite.title || addedSite.title.trim() === '' || addedSite.title === 'NULL') {
        console.log(`title: ${addedSite.title}`);
        const url = new URL(addedSite.url);
        addedSite.title = url.hostname.replace('www.', ''); // 기본적으로 도메인 이름을 title로 설정
      } 
    //   console.log(`addedSite.title: ${addedSite.title}`); 

      setSitelist([...sitelist, addedSite]);
      setNewSite({ name: '', userEmail: '', url: '' });

      fetchSitelist();
      if (isMobileDevice) window.scrollTo(0, 0);
    })
    .catch((error) => console.error("Error adding new site:", error));

    // setTimeout을 사용하여 DOM 업데이트 후 focus를 설정
    setTimeout(() => {
      if (newCardRef.current) {
        newCardRef.current.focus();
      }
    }, 0);
  };
  

  const deleteSite = (site) => {
    console.log(`site.userEmail: ${site.userEmail}`);
    
    switch (tableName) {
          case 'defaultSites':
            axios.delete(`${SERVER_ROOT}/api/sitelist`, { 
              params: { 
                id: site.id, 
                requesterUserId: userId, 
                requesterEmail: user.email,
                url: site.url, 
                userEmail: site.userEmail
              } 
            })
            .then(() => {  
              setDefaultSites(defaultSites.filter(item => !(item.id === site.id && item.userEmail === user.Email && item.url === site.url)));
              if (user.email === 'gategil1149@gmail.com') { 
                setDefaultSites(defaultSites.filter(item => !(item.id === site.id && item.userEmail === site.userEmail && item.url === site.url)));
              } 
            })
            .catch((error) => console.error("Error deleting site:", error));
            break;
        
        case 'sitelist':
        axios.delete(`${SERVER_ROOT}/api/sitelist`, { 
          params: { 
            id: site.id, 
            requesterUserId: userId, 
            requesterEmail: user.email,
            url: site.url, 
            userEmail: site.userEmail
          } 
        })
        .then(() => { 
          if (user.email === 'gategil1149@gmail.com') {
            setSitelist(sitelist.filter(item => !(item.id === site.id && item.userEmail === site.userEmail && item.url === site.url)));
          } else {
            // setSitelist(sitelist.filter(item => item.id !== site.id || item.userId !== userId || item.url !== site.url));
            setSitelist(sitelist.filter(item => !(item.id === site.id && item.userEmail === user.email && item.url === site.url)));
          }

        })
        .catch((error) => console.error("Error deleting site:", error));
        break;
      case 'sharedlist':
        axios.delete(`${SERVER_ROOT}/api/sharedlist`, { 
          params: { 
            id: site.id, 
            requesterUserId: userId, 
            requesterEmail: user.email,
            url: site.url, 
            userEmail: site.userEmail
          } 
        })
        .then(() => {
          setSharedList(sharedList.filter(item => !(item.id === site.id && item.userEmail === user.Email && item.url === site.url)));
          if (user.email === 'gategil1149@gmail.com') {
            setSharedList(sharedList.filter(item => !(item.id === site.id && item.userEmail === site.userEmail && item.url === site.url)));
          } 
        })
        .catch((error) => console.error("Error deleting site:", error));
        break;
      default:
        break;
    }
    
  }; 

  const handleMouseEnter = (type, message) => {
    
    console.log(`type: ${type}`);
    console.log(`message: ${message}`);

    setTooltipType(type);
    setTooltipContent(message);
    setTooltipVisible(true); 
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleLongPress = (url) => {
    if (isMobileDevice) {
      setTooltipType('url');
      setTooltipContent(url);
      setTooltipVisible(true);
    }
  };

  const handleTooltipClick = () => {
    if (isMobileDevice) setTooltipVisible(false);
  };

  const getValueByName = (dataArray, name) => {
    const record = dataArray.find(record => record.name === name);
    return record ? record.value : null;
  };

  const handleTitleDoubleClick = (id, url, title) => {
    setEditingTitleId(id);
    setEditingTitleUrl(url);
    setEditedTitle(title);
  };

  const handleTitleChange = (e) => setEditedTitle(e.target.value);

  const saveTitle = (id, url) => {
    setSitelist(sitelist.map(site => 
      site.url === url ? { ...site, title: editedTitle } : site
    ));
    axios.put(`${SERVER_ROOT}/api/sitelist/title`, { title: editedTitle, url }) // URL도 함께 전달
      .then(() => {
        setEditingTitleId(null); 
        setEditingTitleUrl(''); 
      })
      .catch((error) => console.error("Error updating title:", error));
  };


  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      saveTitle(editingTitleId);
      setEditingTitleId(null);
      setEditingTitleUrl('');
    }
  };

  useEffect(() => {
    if (editingTitleId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [editingTitleId]);

  useEffect(() => {
    const tableName = language !== 'KR' ? 'webpage_EN' : 'webpage';
    axios.get(`${SERVER_ROOT}/api/${tableName}`)
      .then((response) => {
        setWebpageData(response.data);
        const sloganRecord = response.data.find(item => item.name === 'App.js_slogun');
        if (sloganRecord) setSloganText(sloganRecord.value);
      })
      .catch((error) => console.error("Error fetching webpage data:", error));
  }, [language]);
  
  function getDomain(url) {
    try {
      const siteUrl = new URL(url);
      const domain = `${siteUrl.hostname}`;
      return domain;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }
   
  // const copyToClipboard = async (url) => {
  //   try {
  //     if (navigator.clipboard && navigator.clipboard.writeText) {
  //       // 최신 브라우저 및 HTTPS 환경에서는 navigator.clipboard 사용
  //       await navigator.clipboard.writeText(url);
  //       setCopySuccess(true);
  //     } else {
  //       // 구형 모바일 브라우저 지원을 위한 fallback
  //       const textArea = document.createElement("textarea");
  //       textArea.value = url;
  //       // textArea.style.position = "fixed"; // 요소가 눈에 보이지 않도록 고정
  //       textArea.style.opacity = "0";      // 투명하게 설정
  //       document.body.appendChild(textArea);
  //       textArea.select();
  //       document.execCommand("copy");
  
  //       setCopySuccess(true);

  //       // 짧은 지연 후 요소 제거
  //       document.body.removeChild(textArea);
  
        
  //     }
  
  //     setTimeout(() => setCopySuccess(false), 2000); // 2초 후에 메시지 숨기기
  //   } catch (err) {
  //     console.error("Failed to copy: ", err);
  //   }
  // };
  
  

  // Function to handle delete button click
  const handleDeleteClick = (site) => {
    setSiteToDelete(site);
    setShowConfirm(true); // Show the confirmation popup
  };

  const handleSuperDeleteClick = (site) => { 
    setSiteToDelete(site);
    setShowConfirm(true); // Show the confirmation popup
  };

  // Function to confirm deletion
  const confirmDelete = () => {
    if (siteToDelete) {
      deleteSite(siteToDelete); // Call the delete function with the site URL
      setShowConfirm(false); // Close the popup
      setSiteToDelete(null); // Reset the state
    }
  };

  // Function to cancel deletion
  const cancelDelete = () => {
    setShowConfirm(false); // Close the popup
    setSiteToDelete(null); // Reset the state
  };

  // Function to handle opening the popup
  const openThumbnailPopup = (site, event) => {
    event.preventDefault(); // Right-click 메뉴 방지
    setSiteToEdit(site);
    setNewThumbnailUrl('');
    setShowThumbnailPopup(true);
  };

  // Function to update the thumbnail URL in the database
  const updateThumbnail = async () => {
    if (!siteToEdit || !newThumbnailUrl) return;
  
    try {
      axios.put(`${SERVER_ROOT}/api/sitelist/thumbnail`, {
        id: siteToEdit.id,
        url: siteToEdit.url,
        thumbnail: newThumbnailUrl
      });
      setSitelist(prevSites =>
        prevSites.map(site =>
          site.id === siteToEdit.id && site.url === siteToEdit.url
            ? { ...site, thumbnail: newThumbnailUrl }
            : site
        )
      );
      setShowThumbnailPopup(false);
      setSiteToEdit(null);
    } catch (error) {
      console.error('Error updating thumbnail:', error);
    }
  };

 
  const [imagesLoaded, setImagesLoaded] = useState(false); // Tracks image preload status 


  // Share Site function
  

  // Function to open the overlay and store the site to share
  const handleShareButtonClick = (site) => {
    setSharedSite(site);
    setShowShareOverlay(true);
  };

  // Function to handle the share submission
  const handleShareSubmit = () => {
    if (!user || !sharedSite) return;

    const data = {
      id: sharedSite.id,
      userId: user.uid,
      name: sharedSite.name,
      userEmail: user.email,
      invitedUserEmail: invitedUserEmail || shareOption,
      url: sharedSite.url,
      title: sharedSite.title,
      description: sharedSite.description,
      favicon: sharedSite.favicon,
      thumbnail: sharedSite.thumbnail,
      thumbnail_local: sharedSite.thumbnail_local,
    };

    axios.post(`${SERVER_ROOT}/api/sharedlist`, data)
      .then(() => {
        setShowShareOverlay(false);
        setInvitedUserEmail(''); // Reset the input
        setShareOption(''); // Reset the option
        alert('Site shared successfully!');
      })
      .catch(error => console.error("Error sharing site:", error));
  };


  const handleSiteURLWindow = (url) => {
    try {
      const baseDomain = new URL(url).hostname;

      // 내부 라우트인 경우 처리
      if (baseDomain === "www.doldari.com") {
        const routePath = new URL(url).pathname; // "/intro" 같은 경로 추출
        navigate(routePath); // React Router의 navigate 사용
      } else {
        // 외부 URL의 경우 새 탭에서 열기
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  };

  
  const toggleLayout = () => {
    setIsVertical(!isVertical); // 정렬 방향 토글
  };
  
  const handleThumbnailClick = (tableName, userEmail, url) => {  

      console.log(`Home.js table: ${tableName}`);
      console.log(`Home.js userEmail: ${userEmail}`);
      console.log(`Home.js url: ${url}`);
  
      // 서버에 visitCount 업데이트 요청
      axios
        .put(`${SERVER_ROOT}/api/update-visit`, { tableName: tableName, userEmail, url })
        .then((response) => {
          console.log(response.data.message);
          // 상태 업데이트가 필요하다면 여기에서 처리
        })
        .catch((error) => {
          console.error('Error updating visitCount:', error);
        }); 
  
    // // 새 탭에서 URL 열기
    // window.open(url, '_blank');
  };

  return (
    <div className="Home">
      {/* <div> */}
        <header className="Home-header">
        </header>

        <main>
        <div className='myBookmarkContainer'
        onClick={(e) => {
          // 클릭한 요소가 mySites의 자식 요소가 아니라면 스크롤 실행
          if (e.target === e.currentTarget) {
            window.scrollTo(0, 0);
          }
        }}>  
          <div className='myBookmarkTitle'> 
              <div className="Greetings-home" style={{fontSize: '16px'}}>
                {/* <img 
                  src={language === 'KR' ? '/images/나의돌다리.png' : '/images/myDoldari.png'} 
                  alt={language === 'KR' ? '나의 돌다리' : 'My Doldari'} 
                  style={{ width: 'auto', height: '50px' }} 
                  onMouseEnter={() => handleMouseEnter('message',
                    language === 'KR' ? '진화하는 돌다리에서 새로운 웹을 경험하세요.' : 'Enjoy a new Web on evolving Doldari.' )}
                  onMouseOut={handleMouseLeave}
                />   */}
                {language === 'KR' ? '오늘도 당신의 돌다리가 되어 드릴께요!' : 'Doldari is your stepping stone!'}
              </div>
              
              {/* {tooltipVisible && tooltipType === 'message' && (
                <div className="tooltip" onClick={handleTooltipClick}>
                  {tooltipContent} 
                </div>
              )} */}
              <div className="subtitles">
                {/* {user? ( */}
                  <div className="tab-buttons"> 
                      
                      <img 
                        src={language !== 'KR' ? "/images/bookmarks.png": "/images/북마크아이콘2.png"} 
                        alt={language !== 'KR' ? 'My Selection' : '북마크'} 
                        onClick={() => {
                            setActiveTab('sitelist');
                            fetchSitelist(); // Call the function to fetch shared list data
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people."
                        )}
                        onMouseOut={handleMouseLeave}
                        onContextMenu={handleRightClickToAddSite} // Right-click to add URL
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'sitelist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />


                    {/* Overlay Window */}
                    {showOverlay && (
                      <div className="popup-overlay" onClick={closeOverlay}>
                        <div className="popup" onClick={(e) => e.stopPropagation()}>
                        <p>
                          {language !== 'KR'
                            ? `Add URL: ${clipboardUrl.length > 30 ? `${clipboardUrl.slice(0, 30)}...` : clipboardUrl}?`
                            : `URL: ${clipboardUrl.length > 30 ? `${clipboardUrl.slice(0, 30)}...` : clipboardUrl}를 추가하시겠습니까?`}
                        </p>
                          <button
                            onClick={() => {
                              if (clipboardUrl) {
                                // setNewSite({url: clipboardUrl });   
                                newSite.url = clipboardUrl;
                                addNewSite(); // Call addNewSite without modification
                                setShowOverlay(false); // Close the overlay after adding
                              } else {
                                alert(language !== 'KR' ? 'No URL to add!' : '추가할 URL이 없습니다!');
                              }
                            }}
                          >
                            {language !== 'KR' ? 'Confirm' : '확인'}
                          </button>
                        </div>
                      </div>
                    )}

                    <img 
                        src={language !== 'KR' ? "/images/learnplay.png": "/images/공부게임아이콘.png"} 
                        alt={language !== 'KR' ? 'Study' : '학습'} 
                        onClick={() => {
                            setActiveTab('learnPlaylist');
                            fetchLearnPlayList(); // Call the function to fetch shared list data
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people."
                        )}
                        onMouseOut={handleMouseLeave} 
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'learnPlaylist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />
                    
                       

                    <img 
                        src={language !== 'KR' ? "/images/memoDiary.png": "/images/메모일기장.png"} 
                        alt={language !== 'KR' ? "Memo" : '메모일기장'} 
                        onClick={() => {
                            setActiveTab('utilitylist');
                            fetchUtilitylist(); // Call the function to fetch shared list data
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '이 버튼을 누르면 유명한 사이트 모음이 나옵니다.' : "Click the button and see the well-known sites."
                        )}
                        onMouseOut={handleMouseLeave}
                        onContextMenu={handleRightClickToAddSite} // Right-click to add URL
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'utilitylist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />
 


                    <img 
                        src={language !== 'KR' ? "/images/shared.png": "/images/공유받은링크.png"} 
                        alt={language !== 'KR' ? 'Invited Contents' : '초대 콘텐츠'} 
                        onClick={() => {
                            setActiveTab('sharedlist');
                            fetchSharedList(); // Call the function to fetch shared list data
                        }}
                        onMouseEnter={() => handleMouseEnter(
                            'message', 
                            language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people."
                        )}
                        onMouseOut={handleMouseLeave}
                        onContextMenu={handleRightClickToAddSite} // Right-click to add URL
                        style={{
                            height: '70px',
                            width: '70px', 
                            cursor: 'pointer', // 버튼처럼 보이도록 마우스 포인터 변경
                            display: 'block', // 다른 요소들과 구분되도록 블록 레벨 설정
                            border: activeTab === 'sharedlist' ? '3px solid orange' : 'none', 
                            borderRadius: '50%',
                        }}
                    />

                    
                    
                    {/* <button onClick={toggleLayout} className="toggle-button">
                      {isVertical ? <BiMoveHorizontal /> : <BiMoveVertical />}
                    </button> */}
                  </div> 
                  
                {/* ) : (
                  <div className="tab-buttons">
                    <button 
                      onClick={() => setActiveTab('defaultSites')} 
                      // className = 'defaultSites'
                      className={activeTab === 'defaultSites' ? 'active' : 'inactive'} 
                      onMouseEnter={() => handleMouseEnter('message',
                        language === 'KR' ? '이 버튼을 누르면 유명한 사이트 모음이 나옵니다.' : "Click the button and see the well-known sites." )}
                      onMouseOut={handleMouseLeave}
                    >
                        {language !== 'KR' ? "Today's Hotest" : 'Hot 사이트'}
                    </button>

                    <button 
                      onClick={() => {
                        setActiveTab('sharedlist');
                        fetchSharedList(); // Call the function to fetch shared list data
                      }} 
                      // className='sharedlist'
                      className={activeTab === 'sharedlist' ? 'active' : 'inactive'}
                      onMouseEnter={() => handleMouseEnter('message',"사이트 방문시 Ecash 증정. 로그인 필수! (예정)")}
                      onMouseOut={handleMouseLeave}
                    >
                      {language !== 'KR' ? 'Invited Contents' : '초대받은 콘텐츠'}
                    </button> 

                    <button 
                      onClick={() => {
                        setActiveTab('learnPlaylist');
                        fetchLearnPlayList(); // Call the function to fetch shared list data
                      }} 
                      // className='learnPlaylist'
                      className={activeTab === 'learnPlaylist' ? 'active' : 'inactive'}
                      onMouseEnter={() => handleMouseEnter('message',"To Appear")}
                      onMouseOut={handleMouseLeave}
                    >
                      {language !== 'KR' ? 'Utilities' : '유용한 도구'}
                    </button> 
                    <button onClick={toggleLayout} className="toggle-button">
                      {isVertical ? <BiMoveHorizontal /> : <BiMoveVertical />}
                    </button>
                  </div> 
                )
                }  */}
                
              </div>
          </div>
          <div className='myBookmarks'>
            

            {activeTab === 'sitelist' && (
              <div className="mySites" >
              {sitelist.length > 0 ? (
                sitelist.map((site, index) => (
                    <div 
                      key={index} 
                      className={`site-card ${isVertical ? 'vertical' : 'horizontal'}`}
                      tabIndex={0} // 포커스를 받을 수 있게 설정
                      ref={index === 1 ? newCardRef : null} // 마지막 카드에 Ref 설정
                    >
                        {site.thumbnail === '' ? ( 
                            <div className="site-thumbnail"
                              onClick={() => {
                                handleThumbnailClick('sitelist', site.userEmail, site.url);
                                handleSiteURLWindow(site.url);     
                              }}
                            >
                            {getDomain(site.url)}
                            </div> 
                        ) : (
                            <div className="site-thumbnail">
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              onClick={() => {
                                handleThumbnailClick('sitelist', site.userEmail, site.url); 
                                handleSiteURLWindow(site.url);     
                              }}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                            </div>
                        )}
                        <div
                            className="site-title"
                            onDoubleClick={() => {
                                setEditingTitleId(site.id);
                                setEditingTitleUrl(site.url);
                                console.log(`site.id and site.url: ${site.id}, ${site.url}.`);
                            }
                            }
                        >
                            {editingTitleUrl === site.url ? (
                                <textarea
                                rows="4" // 4줄로 제한
                                className="editingTitleInput"
                                type="text"
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                onBlur={() => {
                                    saveTitle(site.id, site.url);
                                    setEditingTitleId(null); // Exit editing mode after saving
                                    setEditingTitleUrl(null);
                                    if (isMobileDevice) {
                                        setTimeout(() => {
                                            window.scrollTo(0, 0);
                                          }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                                      }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        saveTitle(site.id, site.url);
                                        setEditingTitleId(null); // Exit editing mode after saving
                                        if (isMobileDevice) {
                                            setTimeout(() => {
                                                window.scrollTo(0, 0);
                                              }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                                        }
                                    }
                                }}
                                autoFocus
                                />
                            ) : (
                              <>
                                <div 
                                    className="site-title" 
                                    onDoubleClick={() => handleTitleDoubleClick(site.id, site.url, site.title)}
                                    onMouseEnter={() => handleMouseEnter('url',site.url)}
                                    onMouseLeave={handleMouseLeave}
                                    onTouchStart={() => handleLongPress(site.url)}
                                >
                                    {he.decode(site.title)}
                                </div>

                                <div className="site-description">
                                {site.description}
                                </div> 
                              </>  
                            )}
                        </div>
                        
                        
                            
                        {showThumbnailPopup && (
                          <div className="popup-overlay" onClick={() => setShowThumbnailPopup(false)}>
                            <div className="popup" onClick={(e) => e.stopPropagation()}>
                              <h4>{language !== 'KR' ? 'Edit Thumbnail URL' : '썸네일 URL 수정'}</h4>
                              <input
                                type="text"
                                value={newThumbnailUrl}
                                onChange={(e) => setNewThumbnailUrl(e.target.value)}
                                placeholder="Enter new thumbnail URL"
                              />
                              <button onClick={updateThumbnail}>
                                {language !== 'KR' ? 'Save' : '저장'}
                              </button>
                              <button onClick={() => setShowThumbnailPopup(false)}>
                                {language !== 'KR' ? 'Cancel' : '취소'}
                              </button>
                            </div>
                          </div>
                        )}
                        
                        {/* {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )} */}

                        
                        <div className='cardAction'>
                          {user && (
                            <>
                              <button className="delete-button-home" onClick={() => {
                                setTableName('sitelist');
                                handleDeleteClick(site);
                                }}>
                                <BiTrash />
                              </button>
                              <button className="share-site" onClick={() => handleShareButtonClick(site)}>
                                <BiShare />
                              </button>
                            </>
                          )}
                          {/* 삭제 확인 팝업 */}
                          {showConfirm && (
                            <div className="popup-overlay" onClick={cancelDelete}>
                              <div className="popup" onClick={(e) => e.stopPropagation()}>
                                <p>{language !== 'KR' ? 'Want to delete ?' : '정말 지울까요?'}</p>
                                <button onClick={confirmDelete} className="confirm-button">
                                  {language !== 'KR' ? 'Confirm' : '확인'}
                                </button>
                                <button onClick={cancelDelete} className="cancel-button">
                                  {language !== 'KR' ? 'Cancel' : '취소'}
                                </button>
                              </div>
                            </div>
                          )}

                          {/* Share Overlay */}
                          {showShareOverlay && (
                            <div className="overlay" onClick={() => setShowShareOverlay(false)}> {/* Close overlay when clicking outside */}
                              <div 
                                className="overlay-content" 
                                onClick={(e) => e.stopPropagation()} /* Prevent click from closing when inside content */
                              >
                                <h3>{language !== 'KR' ? 'Share this site' : '이 사이트 공유하기'}</h3>
                                
                                <input
                                  type="email"
                                  placeholder="Enter invited user email"
                                  value={invitedUserEmail}
                                  onChange={(e) => setInvitedUserEmail(e.target.value)}
                                  onKeyDown={(e) => e.key === 'Enter' && handleShareSubmit()}
                                />
                                
                                <div className="share-options">
                                  <label>
                                    <input
                                      type="checkbox"
                                      value="To all"
                                      checked={shareOption === 'To all'}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setShareOption('To all');
                                          setInvitedUserEmail(''); // Clear any manually entered email if 'To all' is selected
                                        } else {
                                          setShareOption('');
                                        }
                                      }}
                                    />
                                    To all
                                  </label>
                                  {/* <label>
                                    <input
                                      type="checkbox"
                                      value="To 100 users"
                                      checked={shareOption === 'To 100 users'}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setShareOption('To 100 users');
                                          setInvitedUserEmail('');
                                        } else {
                                          setShareOption('');
                                        }
                                      }}
                                    />
                                    To 100 users
                                  </label> */}
                                </div>
                                
                                <button onClick={handleShareSubmit}>
                                  {language !== 'KR' ? 'Submit' : '확인'}
                                </button>
                              </div>
                            </div>
                          )}



                          {/* <button className="copy-button" onClick={() => {
                              copyToClipboard(site.url);
                              if (isMobileDevice) {
                                  setTimeout(() => {
                                      window.scrollTo(0, 0);
                                    }, 100); 
                              }
                            }}
                            >
                            <BiCopy />
                          </button> */}

                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>
                        </div>
                        
                    </div>
                )) 
              ) : (
                <p className="no-sites-message"> {getValueByName(webpageData, 'Home.no_registered_url')}</p>
              )}

              
              </div>  
            )}

            {/* {tooltipVisible && tooltipType === 'message' && (
              <div className="tooltip" onClick={handleTooltipClick}>
                {tooltipContent} 
              </div>
            )} */}

            {activeTab === 'defaultSites' && (
              <div className="default-sites">
                {defaultSites.length > 0 ? (
                  defaultSites.map((site, index) => (
                    <div key={index} className={`site-card ${isVertical ? 'vertical' : 'horizontal'}`} >
                      {site.thumbnail === '' ? (
                        <div className="text-thumbnail"
                          onClick={() => {
                            handleThumbnailClick('sitelist', site.userEmail, site.url);  
                            handleSiteURLWindow(site.url);    
                            
                          }}
                        >{getDomain(site.url)}</div>
                      ) : (
                        <img
                          src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                          // onClick={() => window.open(site.url, '_blank')}
                          onClick={() => {
                            handleThumbnailClick('sitelist', site.userEmail, site.url);    
                            handleSiteURLWindow(site.url);  
                            
                          }}
                          alt={`${site.name} thumbnail`}
                          className="site-thumbnail"
                        />
                      )}
                       
                      <div 
                          className="site-title"  
                          onMouseEnter={() => handleMouseEnter('url',site.url)}
                          onMouseLeave={handleMouseLeave}
                          onTouchStart={() => handleLongPress(site.url)}
                      >
                          {he.decode(site.title)}
                      </div>

                      {/* {tooltipVisible && tooltipContent === site.url && (
                        <div className="tooltip" onClick={handleTooltipClick}>
                            {site.url}
                        </div>
                      )} */}
                      
                      <div className='cardAction'>
                          {user && (user.email !== site.userEmail && user.email == 'gategil1149@gmail.com') && ( 
                                <button className="super-delete-button" onClick={() => {
                                  setTableName('defaultSites');
                                  handleSuperDeleteClick(site);
                                  }}>
                                  <BiTrash /> 
                                </button>
                          )
                          }
                          
                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>

                          {/* 삭제 확인 팝업 */}
                          {showConfirm && (
                            <div className="popup-overlay" onClick={cancelDelete}>
                              <div className="popup" onClick={(e) => e.stopPropagation()}>
                                <p>{language !== 'KR' ? 'Want to delete ?' : '정말 지울까요?'}</p>
                                <button onClick={confirmDelete} className="confirm-button">
                                  {language !== 'KR' ? 'Confirm' : '확인'}
                                </button>
                                <button onClick={cancelDelete} className="cancel-button">
                                  {language !== 'KR' ? 'Cancel' : '취소'}
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    
                  ))
                ) : (
                  <p className="no-sites-message">
                    {language !== 'KR' ? 'No default sites available' : '등록된 기본 사이트가 없습니다'}
                  </p>
                )}
              </div>
            )}

            {/* Sharedlist Tab */}
            {activeTab === 'sharedlist' && (
              <div className="sharedSites" >
                {sharedList.length > 0 ? (
                  sharedList.map((site, index) => (
                    <div key={index} className={`site-card ${isVertical ? 'vertical' : 'horizontal'}`} >
                        {site.thumbnail === '' ? (
                            <div className="text-thumbnail"
                              onClick={() => {
                                handleThumbnailClick('sharedlist', site.userEmail, site.url); 
                                handleSiteURLWindow(site.url);    
                                
                              }}
                            >
                            {getDomain(site.url)}
                            </div>
                        ) : (
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              // onClick={() => window.open(site.url, '_blank')}
                              onClick={() => {
                                handleThumbnailClick('sharedlist', site.userEmail,  site.url); 
                                handleSiteURLWindow(site.url);   
                                
                              }}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                        )} 
                             
                        <div 
                            className="site-title"  
                            onMouseEnter={() => handleMouseEnter('url',site.url)}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={() => handleLongPress(site.url)}
                        >
                            {he.decode(site.title)}
                        </div>

                       
                        {/* {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )} */}

                        <div className='cardAction'> 
                          

                          {user && user.email === site.userEmail && ( 
                                <button className="delete-button-home" onClick={() => {
                                  setTableName('sharedlist');
                                  handleDeleteClick(site);
                                  }}>
                                  <BiTrash />
                                </button>
                          )
                          }
                          
                          {user && (user.email !== site.userEmail && user.email == 'gategil1149@gmail.com') && ( 
                                <button className="super-delete-button" onClick={() => {
                                  setTableName('sharedlist');
                                  handleSuperDeleteClick(site);
                                  }}>
                                  <BiTrash /> 
                                </button>
                          )
                          }

                          {true && (
                            <> 
                              <button
                                className="share-button"
                                onClick={() => {
                                  if (navigator.share) {
                                    navigator.share({
                                      title: site.title || "Check out this site",
                                      text: '',
                                      url: site.url
                                    })
                                    .then(() => console.log('Successfully shared'))
                                    .catch((error) => console.error('Error sharing:', error));
                                  } else {
                                    alert("Sharing is not supported on this device.");
                                  }
                                }}
                              >
                                <BiShareAlt /> {/* Share icon */}
                              </button>
                            </>
                          )}
                          {/* 삭제 확인 팝업 */}
                          {showConfirm && (
                            <div className="popup-overlay" onClick={cancelDelete}>
                              <div className="popup" onClick={(e) => e.stopPropagation()}>
                                <p>{language !== 'KR' ? 'Want to delete ?' : '정말 지울까요?'}</p>
                                <button onClick={confirmDelete} className="confirm-button">
                                  {language !== 'KR' ? 'Confirm' : '확인'}
                                </button>
                                <button onClick={cancelDelete} className="cancel-button">
                                  {language !== 'KR' ? 'Cancel' : '취소'}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        
                    </div>
                  ))
                ) : (
                  <p className="no-sites-message">{language !== 'KR' ? 'No shared sites available' : '등록된 공유 사이트가 없습니다'}</p>
                )}
              </div>
            )}

            {/* memoDiary Tab */}
            {activeTab === 'utilitylist' && (
              <div className="utilitylist" >
                {utilitylist.length > 0 ? (
                  utilitylist.map((site, index) => (
                    <div key={index} className={`site-card ${isVertical ? 'vertical' : 'horizontal'}`} >
                        {site.thumbnail === '' ? (
                            <div className="text-thumbnail"
                              onClick={() => {
                                handleThumbnailClick('utilitylist', site.userEmail, site.url); 
                                handleSiteURLWindow(site.url);    
                                
                              }}
                            >
                            {getDomain(site.url)}
                            </div>
                        ) : (
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              // onClick={() => window.open(site.url, '_blank')}
                              onClick={() => {
                                handleThumbnailClick('utilitylist', site.userEmail, site.url);
                                handleSiteURLWindow(site.url);    
                                
                              }}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                        )} 
                            
                        <div 
                            className="site-title"  
                            onMouseEnter={() => handleMouseEnter('url',site.url)}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={() => handleLongPress(site.url)}
                        >
                            {he.decode(site.title)}
                        </div>

                       
                        {/* {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )} */}

                        <div className='cardAction'> 
                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>
                        </div>
                        
                    </div>
                  ))
                ) : (
                  <p className="no-sites-message">{language !== 'KR' ? 'No memoDiary sites available' : '등록된 메모 사이트가 없습니다'}</p>
                )}
              </div>
            )}

            {/* learnPlay Tab */}
            {activeTab === 'learnPlaylist' && (
              <div className="learnPlaylist" >
                {learnPlaylist.length > 0 ? (
                  learnPlaylist.map((site, index) => (
                    <div key={index} className={`site-card ${isVertical ? 'vertical' : 'horizontal'}`} >
                        {site.thumbnail === '' ? (
                            <div className="text-thumbnail"
                              onClick={() => {
                                handleThumbnailClick('learnPlaylist', site.userEmail, site.url); 
                                handleSiteURLWindow(site.url);    
                                
                              }}
                            >
                            {getDomain(site.url)}
                            </div>
                        ) : (
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              // onClick={() => window.open(site.url, '_blank')}
                              onClick={() => {
                                handleThumbnailClick('learnPlaylist', site.userEmail, site.url);
                                handleSiteURLWindow(site.url);    
                                
                              }}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                        )} 
                            
                        <div 
                            className="site-title"  
                            onMouseEnter={() => handleMouseEnter('url',site.url)}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={() => handleLongPress(site.url)}
                        >
                            {he.decode(site.title)}
                        </div>

                       
                        {/* {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )} */}

                        <div className='cardAction'> 
                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>
                        </div>
                        
                    </div>
                  ))
                ) : (
                  <p className="no-sites-message">{language !== 'KR' ? 'No shared sites available' : '등록된 공유 사이트가 없습니다'}</p>
                )}
              </div>
            )}

            
            {/* {!user ? ( 
                <div className='message_to_new_visitors'>
                  {language !== 'KR'
                    ? "You'll have a convenient web bookmark just for you."
                    : "이제부터 나만을 위한 편리한 웹 콘텐츠 북마크를 갖게 되실 것입니다."
                  }
                </div>
            ) : ( 
                <div className='message_to_new_visitors'>
                  {language !== 'KR'
                    ? "If you'd like to change the title text, double click on it."
                    : "제목을 두번 클릭하시면, 수정하실 수 있습니다."
                  }
                </div> 
            )} */}
          </div> 

          {activeTab === 'sitelist' && user && (
              <div className="add-site-form">
                <input
                  type="text"
                  placeholder={
                    user
                      ? language !== 'KR'
                        ? "Enter a URL (e.g., www.google.com)"
                        : "URL을 입력하세요. (예, www.google.com)"
                      : language !== 'KR'
                      ? "Please login to add your sites."
                      : "로그인을 먼저 하세요."
                  } 
                    
                  value={newSite.url}
                  style={{
                    width: isMobileDevice ? '60vw' : '30vw'
                  }}
                  onLoad={() => {
                    if (isMobileDevice) {
                        setTimeout(() => {
                            window.scrollTo(0, INPUT_UPSLIDE);
                          }, 100);  
                    }
                    setNewSite({
                      url: language !== 'KR' 
                        ? "Enter a new URL." 
                        : "새 사이트를 입력해주세요."
                    });
                  }}
                  onFocus={() => {
                    if (isMobileDevice) {
                        setTimeout(() => {
                            window.scrollTo(0, INPUT_UPSLIDE);
                          }, 100);  
                    }
                    setNewSite({
                      url: ""
                    });
                  }}
                  onChange={(e) => { 
                    if (user) {
                        setNewSite({ url: e.target.value });
                    } else {
                        setNewSite({
                            url: language !== 'KR' 
                              ? "Login first, please." 
                              : "로그인을 하신 후에 추가하실 수 있어요."
                        });
                    }
                  }}
                  onBlur={() => {
                    if (isMobileDevice) {
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                          }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                      }
                  }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (isMobileDevice) {
                                setTimeout(() => {
                                    window.scrollTo(0, 0);
                                }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                            }
                            if (user) {
                                addNewSite();
                            } else {
                                setNewSite({ url: "Login first, please." }); 
                            }
                            
                        }
                  }}
                />
                
                <button className="add-site-submit-button" onClick={addNewSite}> 
                    {language !== 'KR' ? 'Submit' : '새 사이트 추가'}
                </button> 
                
                

              </div>
            )}

        
        </div>  
        </main>

 
    </div>
  );
}

export default HomeLearnPlay;
