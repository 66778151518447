// Filename: Homehuggingface.js
import React, { useState } from "react";
import "./Homehuggingface.css";

function Homehuggingface() {
  const [inputText, setInputText] = useState("");
  const [imageURL, setImageURL] = useState("/images/imageboxdefault.png");
  const [isLoading, setIsLoading] = useState(false);

  const query = async (data) => {
    try {
      const response = await fetch(
        "https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-dev",
        {
          headers: {
            Authorization: "Bearer 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",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error in query function:", error);
      throw error;
    }
  };

  const handleGenerate = async () => {
    if (!inputText) {
      return;
    }

    setImageURL("/images/imageboxdefault.png");
    setIsLoading(true);
    try {
      const result = await query({ inputs: inputText });
      setImageURL(result);
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="huggingface-container">
        <h2 style={{width: '400px'}}>AI image (FLUX.1-dev model) </h2>

      <div className="input-section">
        
        <div className="input-box" style={{marginTop: '30px'}}>
          <h3> Describe the image to create, please.</h3>
          <textarea
            id="inputText"
            rows="3"
            placeholder="Example: widescreen image of a happy family of parents and children smiling in a worldland"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </div>
      </div>
 
        <button className="huggingfaceButton" onClick={handleGenerate} disabled={isLoading}>
          {isLoading ? "Generating...Please wait." : "Generate Image"}
        </button>  

        {isLoading && (  
            <div className="spinner"></div> 
        )}

      <div className="output-section" style={{marginTop: '20px'}}>
        <h2>Output</h2>
        {imageURL && (
          <div className="image-display">
            <img
              src={imageURL}
              alt="Generated Output"
              onLoad={() => console.log("Image loaded successfully.")}
              onError={() => console.error("Error loading image.")}
            />
          </div>
        )}
        
      </div>
    </div>
  );
}

export default Homehuggingface;
