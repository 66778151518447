import React from 'react';
import './HomeAdv.css'; // 스타일 가져오기

const HomeAdv = () => {
  return (
    <div className="home-adv-container">
      {/* <h3 className="home-adv-title">Advertisement</h3> */}
      {/* <p className="home-adv-description">Your ad could be here!</p> */}
      
      <a
        className="home-adv-link"
        href="https://example.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
            className="home-adv-image"
            src="https://www.doldari.com/images/homeadvimage.png"
            alt="Sample Advertisement"
            style={{ width: '350px', height: 'auto' }} 
        />
      </a>
    </div>
  );
};

export default HomeAdv;
